import {
  DataGridPremium,
  GridCallbackDetails,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { enUS, frFR } from '@mui/x-data-grid-premium/locales';
import { TFunction, i18n as Typei18b } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getDefaultColumnVisibilityModel, useSaveGridState } from '../../../common/helpers/DatagridHelpers';
import { HeaderInfo } from '../../../common/types/table/HeaderInfo';
import { noBordersAndPointer } from '../../../mui/muiGridStyle';

import { format } from 'date-fns';
import { Path } from '../../../paths';
import { AutomationRuleListItemResult } from '../models/automatisationModel';
import { CommonTableToolbar } from '../../../common/components/table/CommonTableToolbar';
import { GridColumnVisibilityModel } from '../../../common/helpers/DataGridPremiumHelpers';
import { GridFooter } from '../../../common/components/table/GridFooter';

type Props = {
  automationRules: AutomationRuleListItemResult[];
  isLoading: boolean;
  count: number;
  paginationModel: GridPaginationModel;
  handlePaginationModelChange: (model: GridPaginationModel, details: GridCallbackDetails) => void;
  sort: GridSortModel;
  onSortModelChange: (sortModel: GridSortModel) => void;
  isRefetching: boolean;
  lastUpdatedAt: Date;
};

type AutomationsTableRow = AutomationRuleListItemResult;

export const gridName = 'workKindsTable';
export const rowsPerPageOptions: number[] = [25, 50, 100];

export const AutomationRulesTable = ({
  automationRules,
  isLoading,
  sort,
  count,
  paginationModel,
  handlePaginationModelChange,
  onSortModelChange,
  isRefetching,
  lastUpdatedAt,
}: Props): JSX.Element => {
  const ref = useGridApiRef();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('configuration', { keyPrefix: 'automations' });
  const dataGridLocalization = i18n.resolvedLanguage === 'fr' ? frFR : enUS;
  const [getColumns, resetColumns, resetSortAndFilters] = useSaveGridState(ref, gridName);

  const headersInformation = useMemo(() => getHeadersInformation(t, i18n), [t, i18n]);
  const tableColumns = [...getColumns<AutomationsTableRow>(t, headersInformation)];
  const defaultColumnVisibilityModel: GridColumnVisibilityModel = useMemo<GridColumnVisibilityModel>(
    () => getDefaultColumnVisibilityModel<AutomationsTableRow>(headersInformation),
    [headersInformation],
  );

  const rows: AutomationsTableRow[] = automationRules;

  const handleRowClick = useCallback(
    (params: GridRowParams) => {
      const id = params.row.id;
      navigate(`/${Path.WorkKindsPage}/${Path.AutomationRulesPage}/${id}`);
    },
    [navigate],
  );

  return (
    <DataGridPremium
      sx={noBordersAndPointer}
      rowSpacingType='border'
      apiRef={ref}
      rows={rows}
      columns={tableColumns}
      localeText={dataGridLocalization.components.MuiDataGrid.defaultProps.localeText}
      disableAggregation
      disableRowGrouping
      disableColumnFilter
      disableRowSelectionOnClick
      loading={isLoading && !isRefetching}
      slotProps={{
        toolbar: {
          headersInformation,
          resetColumns,
          resetSortAndFilters,
        },
        footer: {
          isRefetching,
          lastUpdatedAt,
        },
      }}
      slots={{
        toolbar: CommonTableToolbar,
        footer: GridFooter,
      }}
      sortModel={sort}
      rowCount={count}
      pagination
      pageSizeOptions={rowsPerPageOptions}
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
      onRowClick={handleRowClick}
      paginationMode='server'
      sortingMode='server'
      onSortModelChange={onSortModelChange}
      initialState={{
        columns: {
          columnVisibilityModel: defaultColumnVisibilityModel,
        },
      }}
    />
  );
};

const getHeadersInformation = (t: TFunction<'configuration', 'automations'>, i18n: Typei18b): HeaderInfo<AutomationsTableRow>[] => {
  return [
    {
      field: 'description',
      label: 'description',
      type: 'string',
      sortable: true,
      renderCell: (props: GridRenderCellParams<AutomationsTableRow, string>) => props.value,
    },
    {
      field: 'contractAgreementSection',
      label: 'contractAgreement',
      type: 'string',
      sortable: true,
      renderCell: (props: GridRenderCellParams<AutomationsTableRow, string>) => {
        return t(`contractAgreementSections.${props.value}`);
      },
    },
    {
      field: 'workKind',
      label: 'workKind',
      type: 'string',
      sortable: true,
      renderCell: (props: GridRenderCellParams<AutomationsTableRow, string>) => {
        const isFr = i18n.languages[0] === 'fr';
        const name = isFr ? props.row.workKindNameFr : props.row.workKindNameEn;
        return `${props.row.workKindCode} - ${name ?? ''}`;
      },
    },
    {
      field: 'createdAt',
      label: 'createdAt',
      type: 'string',
      sortable: true,
      renderCell: (props: GridRenderCellParams<AutomationsTableRow, string>) => {
        return props.value ? format(new Date(props.value), 'yyyy-MM-dd') : '';
      },
    },
    {
      field: 'createdBy',
      label: 'createdBy',
      type: 'string',
      sortable: true,
      renderCell: (props: GridRenderCellParams<AutomationsTableRow, string>) => props.value,
    },
  ];
};
