import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';
import { AutomationRuleRecord } from '../../models/automatisationModel';
import { AutomationRuleFormDataDto } from '../AutomationRuleForm';

type UpdateAutomationRuleRequest = {
  automationId: string;
  input: AutomationRuleFormDataDto;
};

type UseUpdateAutomationRuleResult = {
  updateAutomationRule: (request: UpdateAutomationRuleRequest) => Promise<AxiosResponse<AutomationRuleRecord>>;
  isLoading: boolean;
};

export const useUpdateAutomationRule = (): UseUpdateAutomationRuleResult => {
  const [isLoading, setIsLoading] = useState(false);

  const updateAutomationRule = async ({
    automationId,
    input,
  }: UpdateAutomationRuleRequest): Promise<AxiosResponse<AutomationRuleRecord>> => {
    setIsLoading(true);
    await axios.put<AutomationRuleRecord, AxiosResponse<AutomationRuleRecord>>(`/automation-rules/${automationId}`, input);
    setIsLoading(false);
    return axios.get<AutomationRuleRecord>(`/automation-rules/${automationId}`);
  };

  return { updateAutomationRule, isLoading };
};
