import { useAccount } from '@azure/msal-react';
import {
  getGridDateOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridColDef,
  GridColTypeDef,
  GridColumnsInitialState,
  GridColumnVisibilityModel,
  GridEventListener,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterModel,
  GridLogicOperator,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { TFunction } from 'i18next';
import _, { isDate } from 'lodash';
import { Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { LinkOperator } from '../../generated/schemas';
import { UserSetting } from '../../types/UserSettings';
import { HeaderCell } from '../components/table/HeaderCell';
import { DateFilterOperators } from '../enums/DateFilterOperatorsEnum';
import { SingleSelectFilterOperators } from '../enums/SingleSelectFilterOperatorsEnum';
import { StringFilterOperators } from '../enums/StringFilterOperatorsEnum';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { HeaderInfo } from '../types/table/HeaderInfo';
import equal from 'fast-deep-equal';
import i18n from '../../i18n';
import { CustomFr } from './DateTimeHelper';
import { enCA } from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { isValid } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers-pro';

export type GridDimensionType = Pick<GridColumnsInitialState, 'dimensions'>['dimensions'];

export const gridRefreshIntervalInMilliseconds = 60000;

export const stringColumnType: GridColTypeDef = {
  type: 'string',
  filterOperators: getGridStringOperators().filter((operator) => operator.value === StringFilterOperators.startsWith),
};

export const stringColumnTypeMultiFilterOperators: GridColTypeDef = {
  type: 'string',
  filterOperators: getGridStringOperators().filter(
    (operator) => operator.value === StringFilterOperators.startsWith || operator.value === StringFilterOperators.contains,
  ),
};

const CustomInputDate = ({ item, applyValue, focusElementRef = null, apiRef }: GridFilterInputValueProps) => {
  const adapterLocale = useMemo(() => (i18n.resolvedLanguage === 'fr' ? CustomFr : enCA), []);

  const handleDateChange = useCallback(
    (newDate: Date | null) => {
      if (isDate(newDate) && isValid(newDate)) applyValue({ ...item, value: newDate });
    },
    [item, applyValue],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={adapterLocale}>
      <DatePicker
        inputRef={focusElementRef}
        value={item.value ? new Date(item.value) : null}
        onChange={handleDateChange}
        label={apiRef.current.getLocaleText('filterPanelInputLabel')}
        format='yyyy-MM-dd'
        slotProps={{
          textField: {
            variant: 'standard',
          },
          inputAdornment: {
            sx: {
              '& .MuiButtonBase-root': {
                marginRight: -1,
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export const dateColumnType: GridColTypeDef = {
  type: 'date',
  filterOperators: getGridDateOperators()
    .filter((operator) => operator.value === DateFilterOperators.is)
    .map((operator) => {
      return {
        ...operator,
        InputComponent: CustomInputDate,
      };
    }),
};

export const singleSelectColumnType: GridColTypeDef = {
  type: 'singleSelect',
  filterOperators: getGridSingleSelectOperators().filter((operator) => operator.value === SingleSelectFilterOperators.is),
};

export const gridSortToString = ([col]: GridSortModel = []): string | undefined => (col ? `${col.field} ${col.sort}` : undefined);

export const gridFilterToString = (col: GridFilterModel): string[] | undefined => {
  if (!col.items) return undefined;
  if (Object.values(col.items).every(({ value }) => value === undefined)) return undefined;

  return col.items.filter(({ value }) => value !== undefined).map(({ value, field: columnField }) => `${columnField} ${value}`);
};

export const gridFilterToStringWithLinkOperator = (col: GridFilterModel): string[] => {
  if (!col.items) return [];
  if (Object.values(col.items).every(({ value }) => value === undefined)) return [];

  return col.items
    .filter(({ value }) => value !== undefined)
    .map(({ value, field: columnField, operator }) => {
      return `${operator} ${columnField} ${value}`;
    });
};

// FIXME: WARNING mutation of prop "headerInfo"
const getColumns =
  (sortArray: string[], dimensions?: GridDimensionType) =>
  <RowModel extends GridValidRowModel>(t: TFunction, headersInformation: HeaderInfo<RowModel>[]): GridColDef[] => {
    return headersInformation
      .map((headerInfo: HeaderInfo<RowModel>): GridColDef => {
        const field: string = headerInfo.field;
        const width = dimensions?.[field]?.width;
        const headerName: string = t(`list.headers.${headerInfo.label}`);
        return {
          field,
          headerName,
          headerClassName: `header-${field}`,
          renderHeader: () => renderHeader(headerName),
          sortable: headerInfo.sortable,
          filterable: headerInfo.filterable,
          minWidth: 110,
          width: width,
          flex: width ? 0 : 1,
          type: headerInfo.type,
          valueOptions: headerInfo.valueOptions,
          renderCell: headerInfo.renderCell,
          filterOperators: headerInfo.filterOperators,
          display: 'flex',
          valueGetter: headerInfo.valueGetter,
        };
      })
      .sort((a, b) => sortArray.indexOf(a.field) - sortArray.indexOf(b.field));
  };

type GetColumns = <RowModel extends GridValidRowModel>(t: TFunction, headersInformation: HeaderInfo<RowModel>[]) => GridColDef[];
export const getDefaultColumnVisibilityModel = <RowModel extends GridValidRowModel>(
  headersInformation: Pick<HeaderInfo<RowModel>, 'field' | 'hide'>[],
): GridColumnVisibilityModel => {
  const defaultColumnVisibilityModel: GridColumnVisibilityModel = {};
  headersInformation.reduce((acc: GridColumnVisibilityModel, headerInformation: Pick<HeaderInfo<RowModel>, 'field' | 'hide'>) => {
    acc[headerInformation.field] = !headerInformation.hide;
    return acc;
  }, defaultColumnVisibilityModel);
  return defaultColumnVisibilityModel;
};

const renderHeader = (value: string): JSX.Element => <HeaderCell value={value} />;

export const defaultFilter: GridFilterModel = { items: [] };
export const defaultSort: GridSortModel = [];
const defaultColumns: GridColumnsInitialState = {};

const defaultUserGridSettings = {
  columns: defaultColumns,
  sort: defaultSort,
  filter: defaultFilter,
};

export const useSaveGridState = (
  ref: MutableRefObject<GridApiPremium>,
  gridName: string,
): readonly [GetColumns, (defaultVisibilityModel: GridColumnVisibilityModel) => void, () => void] => {
  const user = useAccount();
  const [value, setValue] = useLocalStorage<UserSetting>(user?.username ?? 'unknown', {
    gridSettings: { [gridName]: defaultUserGridSettings },
  });
  const { columns } = value.gridSettings[gridName] ?? defaultUserGridSettings;
  const _getColumns = getColumns(columns.orderedFields || [], columns.dimensions || {});

  const resetColumns = useCallback(
    (defaultVisibilityModel: GridColumnVisibilityModel) => {
      setValue({
        gridSettings: {
          ...value.gridSettings,
          [gridName]: {
            ...(value.gridSettings[gridName] ?? defaultUserGridSettings),
            columns: {
              columnVisibilityModel: defaultVisibilityModel,
              dimensions: {},
              orderedFields: [],
            },
          },
        },
      });
    },
    [value, setValue, gridName],
  );

  const resetSortAndFilters = useCallback(() => {
    setValue({
      gridSettings: {
        ...value.gridSettings,
        [gridName]: {
          ...(value.gridSettings[gridName] ?? defaultUserGridSettings),
          filter: defaultFilter,
          sort: defaultSort,
        },
      },
    });
  }, [setValue, value, gridName]);

  useEffect(() => {
    const handleColumnWidthChange: GridEventListener<'columnWidthChange'> = (params) => {
      const newSettings = { ...(value.gridSettings[gridName] ?? defaultUserGridSettings) };
      const newColumns = newSettings.columns ?? defaultColumns;

      setValue({
        gridSettings: {
          ...value.gridSettings,
          [gridName]: {
            ...newSettings,
            columns: {
              ...newColumns,
              dimensions: {
                ...(newColumns.dimensions ?? {}),
                [params.colDef.field]: { width: params.width },
              },
            },
          },
        },
      });
    };
    return ref.current.subscribeEvent('columnWidthChange', handleColumnWidthChange);
  }, [ref, value, setValue, gridName]);

  useEffect(() => {
    const handleColumnPositionChange: GridEventListener<'columnOrderChange'> = () => {
      const newSettings = { ...(value.gridSettings[gridName] ?? defaultUserGridSettings) };

      setValue({
        gridSettings: {
          ...value.gridSettings,
          [gridName]: {
            ...newSettings,
            columns: {
              ...(newSettings.columns ?? defaultColumns),
              orderedFields: ref.current.exportState()?.columns?.orderedFields,
            },
          },
        },
      });
    };
    return ref.current.subscribeEvent('columnOrderChange', handleColumnPositionChange);
  }, [ref, value, setValue, gridName]);

  useEffect(() => {
    const handleColumnVisibilityChange: GridEventListener<'columnVisibilityModelChange'> = (params) => {
      const newSettings = { ...(value.gridSettings[gridName] ?? defaultUserGridSettings) };
      setValue({
        gridSettings: {
          ...value.gridSettings,
          [gridName]: {
            ...newSettings,
            columns: {
              ...(newSettings.columns ?? defaultColumns),
              columnVisibilityModel: params,
            },
          },
        },
      });
    };
    return ref.current.subscribeEvent('columnVisibilityModelChange', handleColumnVisibilityChange);
  }, [ref, value, setValue, gridName]);

  useEffect(() => {
    Object.entries(columns.columnVisibilityModel || []).forEach(([column, visible]) => {
      ref.current.setColumnVisibility(column, visible);
    });
  }, [columns, ref]);

  return [_getColumns, resetColumns, resetSortAndFilters] as const;
};

export const useHandleFilterModelChange = (
  // FIXME: should not pass a state setter as a callback but use a onXChange event instead
  setFilters: Dispatch<SetStateAction<string[] | undefined>>,
  // FIXME: should not pass a state setter as a callback but use a onXChange event instead
  setPageIndex: Dispatch<SetStateAction<number>>,
) => {
  return useCallback(
    (filterModel: GridFilterModel) => {
      const _filterBy = gridFilterToString(filterModel);

      if (_filterBy) {
        setFilters(_filterBy);
      } else {
        setFilters([]);
      }
      setPageIndex(0);
    },
    [setFilters, setPageIndex],
  );
};

export const useSavedGridSort = (gridName: string, customDefaultSort?: GridSortModel): [GridSortModel, (sort: GridSortModel) => void] => {
  const user = useAccount();
  const [value, setValue] = useLocalStorage<UserSetting>(user?.username ?? 'unknown', {
    gridSettings: { [gridName]: defaultUserGridSettings },
  });
  const { sort } = value.gridSettings[gridName] ?? defaultUserGridSettings;
  const setSort = useCallback(
    (updatedSort: GridSortModel) => {
      const userSetting = _.cloneDeep(value);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      userSetting.gridSettings = {
        ...userSetting.gridSettings,
        [gridName]: {
          ...(userSetting.gridSettings[gridName] ?? defaultUserGridSettings),
          sort: updatedSort,
        },
      };
      if (equal(value, userSetting)) return;
      setValue(userSetting);
    },
    [gridName, setValue, value],
  );

  return useMemo(() => [sort?.length ? sort : (customDefaultSort ?? []), setSort], [customDefaultSort, sort, setSort]);
};

export const useSavedGridFilter = (gridName: string): [GridFilterModel, (filter: GridFilterModel) => void] => {
  const user = useAccount();

  const [value, setValue] = useLocalStorage<UserSetting>(user?.username ?? 'unknown', {
    gridSettings: { [gridName]: defaultUserGridSettings },
  });
  const { filter } = value.gridSettings[gridName] ?? defaultUserGridSettings;

  const setFilter = useCallback(
    (updatedFilter: GridFilterModel) => {
      const dateFilters = updatedFilter.items.filter((x) => isDate(x.value));
      dateFilters.forEach((datefilter: GridFilterItem) => {
        datefilter.value = (datefilter.value as Date).toISOString();
      });
      const userSetting = _.cloneDeep(value);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete updatedFilter.operatorValue; // delete legacy operatorValue
      userSetting.gridSettings = {
        ...userSetting.gridSettings,
        [gridName]: {
          ...(userSetting.gridSettings[gridName] ?? defaultUserGridSettings),
          filter: updatedFilter,
        },
      };
      if (equal(value, userSetting)) return;
      setValue(userSetting);
    },
    [gridName, setValue, value],
  );

  return useMemo(() => [filter, setFilter], [filter, setFilter]);
};

export const gridLinkOperatorToLinkOperator = (gridLinkOperator?: GridLogicOperator | undefined): LinkOperator => {
  switch (gridLinkOperator) {
    case GridLogicOperator.And:
      return LinkOperator.And;
    case GridLogicOperator.Or:
      return LinkOperator.Or;
    default:
      return LinkOperator.And;
  }
};
