import { useFetchWorkKinds } from '../hooks/useFetchWorkKinds';
import { WorkKindRuleListItemRecord } from '../models/workKindsModel';
import AutomationRuleDetailHeader, { useHeaderPageContext } from './AutomationRuleDetailHeader';
import { AutomationRuleForm } from './AutomationRuleForm';

export const NewAutomationRulePage = (): JSX.Element => {
  const { data, isLoading } = useFetchWorkKinds();
  const workKindsData = data?.items ?? [];
  return (
    <AutomationRuleDetailHeader isLoading={isLoading}>
      <WrapAutomationFormWithHeaderContext isLoading={isLoading} workKinds={workKindsData} />
    </AutomationRuleDetailHeader>
  );
};

function WrapAutomationFormWithHeaderContext({ isLoading, workKinds }: { isLoading: boolean; workKinds: WorkKindRuleListItemRecord[] }) {
  const { actionsRef } = useHeaderPageContext();
  return <AutomationRuleForm mode='create' automationRule={null} actionsRef={actionsRef} isLoading={isLoading} workKinds={workKinds} />;
}
