import { QueryClient } from '@tanstack/react-query';
import { defer } from 'react-router-dom';
import { workKindsQueryOptions } from './hooks/useFetchWorkKinds';
import { WorkKindRuleRecord } from './models/workKindsModel';

export type WorkKindRuleLoaderData = {
  workKindRule: { data?: WorkKindRuleRecord };
};

export const workKindRulesLoader = (queryClient: QueryClient) => (): ReturnType<typeof defer> => {
  const fetchWorkKinds = queryClient.fetchQuery({
    ...workKindsQueryOptions(),
    staleTime: 1000 * 60 * 2, // 2 minutes
  });
  return defer({
    workKinds: fetchWorkKinds,
  });
};
