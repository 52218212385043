import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import axios, { InternalAxiosRequestConfig } from 'axios';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { apiRequest } from '../../authConfig';
import { config } from '../../config';
import i18n from '../../i18n';
import useSignalr from './useSignalr';
import { dispatchHubName } from '../../dispatch/constants/constants';

export const useAxios = (account: AccountInfo | null, instance: IPublicClientApplication): boolean => {
  const [isReady, setIsReady] = useState(false);
  const { connectionId } = useSignalr(`${config.HUB_BASE_URL}/${dispatchHubName}`);

  const instanceRef = useRef(instance);
  instanceRef.current = instance;

  useEffect(() => {
    if (!account) return;
    removeAllInterceptors();
    axios.defaults.baseURL = config.API_URL;
    const requestInterceptorId = axios.interceptors.request.use(requestInterceptorOnFulfilled(instanceRef, account, connectionId));
    setIsReady(true);
    return () => {
      axios.interceptors.request.eject(requestInterceptorId);
      setIsReady(false);
    };
  }, [account, instanceRef, isReady, connectionId]);

  return isReady;
};

const requestInterceptorOnFulfilled =
  (instanceRef: MutableRefObject<IPublicClientApplication>, activeAccount: AccountInfo, connectionId: string | undefined) =>
  async (request: InternalAxiosRequestConfig) => {
    const tokenRequest = { ...apiRequest, account: activeAccount };
    const controller = new AbortController();
    request.headers['Accept-Language'] = i18n.languages[0];
    request.headers['SignalRConnectionId'] = connectionId;

    try {
      const { accessToken, expiresOn } = (await instanceRef.current.acquireTokenSilent(tokenRequest)) || {};
      if (expiresOn && expiresOn < new Date()) {
        controller.abort();
      } else {
        request.headers.Authorization = `Bearer ${accessToken}`;
      }
    } catch {
      await instanceRef.current.acquireTokenRedirect(tokenRequest);
    }

    return { ...request, signal: controller.signal };
  };

/**
  Rare case: Hack to force eject all unmanaged interceptors from axios
  axios v1 provides `.clear()` method to remove all interceptors but axios v0.27 doesn't
  so we have to manually remove all interceptors
  @soure https://github.com/axios/axios/blob/v0.27.2/lib/core/InterceptorManager.js
 */
const removeAllInterceptors = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const requestInterceptors: (object | null)[] = (axios.interceptors.request.handlers || []).filter(Boolean);
  if (requestInterceptors.length > 0) {
    console.info('Axios: Eject all unmanaged request interceptors', requestInterceptors.length);
    requestInterceptors.forEach((_, index) => {
      axios.interceptors.request.eject(index);
    });
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const responseInterceptors: (object | null)[] = (axios.interceptors.response.handlers || []).filter(Boolean);
  if (responseInterceptors.length > 0) {
    console.info('Axios: Eject all unmanaged response interceptors', responseInterceptors.length);
    responseInterceptors.forEach((_, index) => {
      axios.interceptors.response.eject(index);
    });
  }
};
