import { Grid2 as Grid, Theme } from '@mui/material';
import { lightGray } from '../../constants/colors';

type Props = {
  children: React.ReactNode;
  tabsComponent?: React.ReactNode;
  paddingX?: number;
  paddingY?: number;
  disabled?: boolean;
  withBorder?: boolean;
  withTabs?: boolean;
  dataTestId?: string;
};

function BaseCard({
  children,
  tabsComponent,
  paddingX = 3,
  paddingY = 2,
  disabled = false,
  withBorder = false,
  withTabs = false,
  dataTestId = undefined,
}: Props): JSX.Element {
  const border = withBorder ? `1px solid ${lightGray}` : 'none';
  return (
    <Grid
      container
      size={12}
      mt={withTabs ? 0 : 1}
      paddingY={paddingY}
      paddingX={paddingX}
      bgcolor={(theme: Theme) => (disabled ? 'rgba(49, 49, 49, 0.08)' : theme.palette.secondary.main)}
      sx={{ borderRadius: 1 }}
      borderTop={!tabsComponent ? border : 'none'}
      borderLeft={border}
      borderRight={border}
      borderBottom={border}
      data-testid={dataTestId}>
      {tabsComponent}
      {children}
    </Grid>
  );
}

function TabsCard({ children }: Props): JSX.Element {
  return (
    <Grid
      container
      size={12}
      mt={1}
      paddingX={0}
      bgcolor={(theme: Theme) => theme.palette.secondary.main}
      sx={{ borderRadius: 1 }}
      borderTop='1px solid ${lightGray}'>
      {children}
    </Grid>
  );
}

export function MainCard({
  children,
  tabsComponent,
  paddingX = 3,
  paddingY = 2,
  disabled = false,
  withBorder = false,
  dataTestId,
}: Props): JSX.Element {
  return (
    <Grid container data-testid={dataTestId} size={12}>
      {tabsComponent && <TabsCard>{tabsComponent}</TabsCard>}
      <BaseCard withBorder={withBorder} paddingX={paddingX} paddingY={paddingY} disabled={disabled} withTabs={!!tabsComponent}>
        {children}
      </BaseCard>
    </Grid>
  );
}
