import { useCallback, useEffect } from 'react';
import { GridSortModel } from '@mui/x-data-grid-premium';

import { ListContainer } from '../../common/components/container/ListContainer';
import { gridSortToString, useSavedGridSort } from '../../common/helpers/DatagridHelpers';
import { useFetchWorkKinds } from './hooks/useFetchWorkKinds';
import { gridName, WorkKindsTable } from './WorkKindsTable';
import { useTableRowCount } from '../../common/hooks/useTableRowCount';

export const WorkKindsRulePage = (): JSX.Element | null => {
  const [sort, setSort] = useSavedGridSort(gridName);
  const formattedOrderBy = gridSortToString(sort);

  const {
    isLoading,
    refetch,
    dataUpdatedAt,
    isRefetching,
    data = { count: 0, items: [] },
  } = useFetchWorkKinds({
    orderBy: formattedOrderBy,
  });

  useEffect(() => {
    refetch();
  }, [refetch, formattedOrderBy]);

  const rowCount = useTableRowCount(data.count);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSort(sortModel);
    },
    [setSort],
  );

  return (
    <ListContainer>
      <WorkKindsTable
        isLoading={isLoading}
        sort={sort}
        count={rowCount}
        onSortModelChange={handleSortModelChange}
        isRefetching={isRefetching}
        workKinds={data.items}
        lastUpdatedAt={new Date(dataUpdatedAt)}
      />
    </ListContainer>
  );
};
