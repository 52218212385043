import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '../../authorization/Ability';
import { Role } from '../../common/models/models';

const editOrdreDeTravailRoles: Role[] = ['dispatcher', 'admin', 'dispatchChief'];
const viewAdvancementFollowupRoles: Role[] = ['dispatcher', 'admin', 'dispatchChief', 'billingClerk', 'validationReadOnly'];

export const ordreDeTravailAbility = 'ordreDeTravail';
export const advancementFollowupAbility = 'ordreDeTravailAdvancement';

export const defineOrdreDeTravailAbilities = (abilityBuilder: AbilityBuilder<AppAbility>, roles: Role[] | undefined): void => {
  const { can } = abilityBuilder;

  if (!!roles?.some((role) => editOrdreDeTravailRoles.includes(role))) can('edit', ordreDeTravailAbility);
  if (!!roles?.some((role) => viewAdvancementFollowupRoles.includes(role))) can('view', advancementFollowupAbility);
};
