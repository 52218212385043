import { InlineTimeInput } from './InlineTimeInput';
import { getTimeRangeDurationInMinutes, rangeFinishesTomorrow } from '../../helpers/TimeRangeHelpers';
import { computedTo } from '../../helpers/DateTimeHelper';
import { NextDayIcon } from '../svg/NextDayIcon';
import { Box, Stack, Tooltip, useTheme } from '@mui/material';
import { Ref, useCallback, useMemo, useRef } from 'react';
import { addDays } from 'date-fns';

export interface TimeRange {
  from: string;
  durationInMinutes: number;
  startsOnNextDay?: boolean | null | undefined;
}
interface TimeRangeControlProps<T extends TimeRange> {
  name: string;
  timeRange: T;
  onBlur: () => void;
  onChange: (durationInMinutes: number) => void;
  parentDate?: Date;
  errorMessage: string | undefined;
  readonly: boolean;
  myRef?: Ref<HTMLInputElement>;
}

export const DurationInMinutes = <T extends TimeRange>({
  name,
  timeRange,
  onChange,
  onBlur,
  errorMessage,
  readonly,
  parentDate = new Date(),
  myRef,
}: TimeRangeControlProps<T>) => {
  const theme = useTheme();
  const isPrevStartsOnNextDayRef = useRef<boolean>(false);
  const nextDay = rangeFinishesTomorrow(timeRange);

  const processParentDate = useCallback(() => {
    return timeRange.startsOnNextDay ? addDays(parentDate!, 1) : parentDate!;
  }, [timeRange, parentDate]);

  const value = useMemo(
    () => computedTo(timeRange.from, timeRange.durationInMinutes, processParentDate()) ?? '',
    [timeRange.durationInMinutes, timeRange.from, processParentDate],
  );

  const handleChange = useCallback(
    (formattedTime: string) => {
      const durationInMinutes = getTimeRangeDurationInMinutes(timeRange.from, formattedTime, processParentDate());
      if (durationInMinutes === timeRange.durationInMinutes) return;
      const applyNextDay = isPrevStartsOnNextDayRef.current && !timeRange.startsOnNextDay;
      onChange(applyNextDay ? durationInMinutes + 24 * 60 : durationInMinutes);
    },
    [onChange, timeRange, processParentDate],
  );

  const handleToggleNextDay = useCallback(() => {
    const durationInMinutes = timeRange.durationInMinutes + (rangeFinishesTomorrow(timeRange) ? -24 * 60 : 24 * 60);
    isPrevStartsOnNextDayRef.current = rangeFinishesTomorrow({ ...timeRange, durationInMinutes });
    onChange(durationInMinutes);
  }, [onChange, timeRange]);

  const handleFocus = useCallback(() => {
    isPrevStartsOnNextDayRef.current = nextDay;
  }, [nextDay]);

  return (
    <Tooltip disableInteractive title={errorMessage ?? ''} placement='top' arrow>
      <Stack
        direction='row'
        sx={{
          ...(errorMessage && {
            color: theme.palette.error.main,
            borderBottom: `1px solid ${theme.palette.error.main}`,
          }),
        }}>
        <InlineTimeInput
          ref={myRef}
          name={name}
          disabled={readonly}
          onBlur={onBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          error={!!errorMessage}
          value={value}
          style={{
            ...(errorMessage && {
              color: theme.palette.error.main,
            }),
          }}
        />
        {!readonly && (
          <Box sx={{ paddingTop: '5px' }}>
            <NextDayIcon
              aria-label={name + '.tomorrow'}
              disabled={!!timeRange.startsOnNextDay}
              onClick={handleToggleNextDay}
              active={nextDay}
              dateToIncrement={parentDate}
            />
          </Box>
        )}
      </Stack>
    </Tooltip>
  );
};
