import { contractAgreementSections } from '../../schemas/__i18n__/en';
import { IncompatibilityTypeEnum } from '../components/Incompatibility/models/incompatibilityModels';

const headers = {
  incompatibilityType: 'Incompatibility type',
  employee: 'Employee',
  incompatibleWith: 'Incompatible with',
  description: 'Description',
  updatedAt: 'Updated at',
  createdBy: 'Created by',
};

const workKindsGridColumns = {
  title: 'Work Kind',
  description: 'Description',
  status: 'Status',
};

const incompatibilityType = {
  [IncompatibilityTypeEnum.Client]: 'Client',
  [IncompatibilityTypeEnum.WorkSite]: 'WorkSite',
  [IncompatibilityTypeEnum.Employee]: 'Employee',
  [IncompatibilityTypeEnum.Equipment]: 'Equipment',
};

const workKinds = {
  list: {
    headers: workKindsGridColumns,
  },
  active: 'Active',
  inactive: 'Inactive',
};

const incompatibility = {
  incompatibility: 'Incompatibility',
  newIncompatibility: 'New Incompatibility',
  list: {
    headers,
  },
  incompatibilityType,
  deleteConfirmationModal: {
    title: 'Delete',
    text: 'Do you really want to delete this incompatibility?',
  },
  validation: {
    sameEmployee: 'Use other employee',
  },
};

const automations = {
  title: 'Work Kinds',
  list: {
    headers: {
      description: 'Description',
      contractAgreement: 'Service Contract',
      workKind: 'Work Kind',
      createdAt: 'Created at',
      createdBy: 'Created by',
    },
  },
  active: 'Active',
  inactive: 'Inactive',
  contractAgreementSections,
};

const tabs = {
  incompatibility: 'Incompatibilities',
  automation: 'Automation',
  workindsList: 'Work Kinds',
};

export const configuration = {
  title: 'Configuration',
  titleWorkKinds: 'Work Kinds Rules',
  create: 'Create',
  incompatibility,
  workKinds,
  tabs,
  automations,
  validation: {
    required: '*Required',
    saveSuccessMessage: 'Modifications saved successfully.',
  },
};
