import { Autocomplete, Checkbox, MenuItem, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { SxProps, Theme } from '@mui/material/styles';
import { forwardRef, useCallback } from 'react';

export type MultiSelectChipInputItemOption = {
  value: string;
  label: string;
};

type MultiSelectChipInputProps = {
  items: MultiSelectChipInputItemOption[];
  label: string;
  value: string[];
  sx?: SxProps<Theme>;
  error?: boolean;
  helperText?: string;
  onChange: (newValue: string[]) => void;
  disabled?: boolean;
};

export const MultiSelectChipInput = forwardRef<HTMLDivElement, MultiSelectChipInputProps>(
  ({ items = [], label = '', value, onChange, error, helperText, sx = {}, disabled = false }, ref) => {
    const selectedOptions = value.map((val) => items.find((item) => item.value === val) || { value: val, label: val });

    const handleChange = useCallback(
      (_event: React.SyntheticEvent, newValue: MultiSelectChipInputItemOption[]) => {
        const newValues = newValue.map((option) => option.value);
        onChange(newValues);
      },
      [onChange],
    );

    return (
      <FormControl sx={{ m: 1, width: 200, ...sx }} ref={ref} fullWidth>
        <Autocomplete
          multiple
          size='small'
          options={items}
          value={selectedOptions}
          disableCloseOnSelect
          onChange={handleChange}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, v) => option.value === v.value}
          disabled={disabled}
          renderOption={(props, option, { selected }) => {
            const { key, ...optionProps } = props;
            return (
              <MenuItem selected={selected} key={key} {...optionProps}>
                <Checkbox sx={{ p: 0.5 }} size='small' style={{ marginRight: 8 }} checked={selected} />
                {option.label}
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size='small'
              disabled={disabled}
              fullWidth
              label={label}
              error={error}
              helperText={error && helperText}
              variant='outlined'
            />
          )}
          slotProps={{
            listbox: {
              style: {
                maxHeight: 225,
              },
            },
          }}
        />
      </FormControl>
    );
  },
);
