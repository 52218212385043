export enum DateFilterOperators {
  is = 'is',
  not = 'not',
  after = 'after',
  onOrAfter = 'onOrAfter',
  before = 'before',
  onOrBefore = 'onOrBefore',
  isEmpty = 'isEmpty',
  isNotEmpty = 'isNotEmpty',
}
