import { AutomationRuleRecord } from '../models/automatisationModel';
import { AutomationRuleFormDataDto } from './AutomationRuleForm';

export const mapRecordToForm = (record: AutomationRuleRecord | null): AutomationRuleFormDataDto => {
  return {
    description: record?.description ?? '',
    serviceCallKinds: record?.serviceCallKinds ?? [],
    workTypes: record?.workTypes ?? [],
    workTypeCategories: record?.workTypeCategories ?? [],
    operatorKinds: record?.operatorKinds ?? [],
    contractAgreementSection: record?.contractAgreementSection ?? null,
    workKindId: record?.workKindId ?? '',
  };
};
