import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { black } from '../../constants/colors';

type Props = {
  to: string | number;
};

export const BackButton = ({ to }: Props): JSX.Element => {
  const navigate = useNavigate();
  const delta = typeof to === 'number' ? to : 0;
  const toPath = typeof to === 'string' ? to : '';
  const handleBackClick = (): void => {
    if (typeof to === 'number') {
      navigate(delta);
    } else {
      navigate(toPath);
    }
  };
  return (
    <IconButton aria-label='back-button' onClick={handleBackClick} sx={{ paddingLeft: 0.5 }}>
      <ArrowBackIcon sx={{ color: black }} />
    </IconButton>
  );
};
