import { Box } from '@mui/material';
import { GridColumnVisibilityModel, GridToolbarColumnsButton, GridToolbarContainer, GridValidRowModel } from '@mui/x-data-grid-premium';
import { charcoalGray } from '../../constants/colors';
import { HeaderInfo } from '../../types/table/HeaderInfo';
import { ResetColumnsButton } from './ResetColumnsButton';
import { ResetSortAndFilterButton } from './ResetSortAndFilterButton';

type Props<RowModel extends GridValidRowModel> = {
  headersInformation: Pick<HeaderInfo<RowModel>, 'field' | 'hide'>[];
  resetColumns: (defaultVisibilityModel: GridColumnVisibilityModel) => void;
  resetSortAndFilters: () => void;
};

export const CommonTableToolbar = <RowModel extends GridValidRowModel>({
  headersInformation,
  resetColumns,
  resetSortAndFilters,
}: Props<RowModel>): JSX.Element => {
  return (
    <GridToolbarContainer role='toolbar' sx={{ marginLeft: '3px', justifyContent: 'space-between' }}>
      <GridToolbarColumnsButton slotProps={{ button: { sx: { textTransform: 'uppercase', color: charcoalGray } } }} />
      <Box>
        <ResetSortAndFilterButton resetSortAndFilters={resetSortAndFilters} />
        <ResetColumnsButton headersInformation={headersInformation} resetColumns={resetColumns} />
      </Box>
    </GridToolbarContainer>
  );
};
