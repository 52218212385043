import { components } from '../../generated/swagger-type';

export type OrdreDeTravailScheduleRecord = components['schemas']['OrdreDeTravailScheduleRecord'];

export type GetContractAgreementRecord = components['schemas']['GetContractAgreementRecord'];
export type UpdateContractAgreementRecord = components['schemas']['UpdateContractAgreementInput'];
export type DailyTimeSheetRecord = components['schemas']['DailyTimeSheetRecord'];
export type TimeSheetEntryRecord = components['schemas']['TimeSheetEntryRecord'];
export type GetCATimesheetRecord = components['schemas']['GetContractAgreementDailyTimesheetRecord'];
export type GetCsWithEntriesSummariesRecord = components['schemas']['ContractAgreementWithTimesheetEntriesAndOperatorRolesSummaryRecord'];

export type UpdateDailyTimeSheetEntryRecord = components['schemas']['UpdateDailyTimesheetEntryRecord'];
export type UpdateBillableEntryRecord = components['schemas']['UpdateDailyTimesheetBillableEntryRecord'];
export type UpdateNonBillableEntryRecord = components['schemas']['UpdateDailyTimeSheetNonBillableEntryRecord'];

export type TimeRangeRecord = components['schemas']['TimeRangeRecord'];
export type PremiumRecord = components['schemas']['PremiumRecord'];
export type AttachmentRecord = components['schemas']['TimeSheetEntryAttachmentRecord'];

export type TotalWeekHoursRecord = components['schemas']['TotalWeekHoursRecord'];

export type PrefacturationRecord = components['schemas']['PrefacturationRecord'];
export type PosteDeTravailRecord = components['schemas']['PosteDeTravailRecord'];
export type CraneOperatorKind = components['schemas']['CraneOperatorKind'];
export type UpdatePrefacturationRequest = {
  ordreDeTravailNumber: number;
  input: components['schemas']['UpdatePrefacturationWithCommentInput'];
};

export type GetCodesDeFacturationRecord = components['schemas']['GetCodesDeFacturationRecord'];
export type WorkForceTypeRecord = components['schemas']['WorkForceTypeRecord'];
export type AccessoryTypeRecord = components['schemas']['AccessoryTypeRecord'];
export type UpdatePurchaseOrder = {
  id: string;
  purchaseOrder: string;
};

export type ContractAgreementSubmissionInformationRecord = components['schemas']['ContractAgreementSubmissionInformationRecord'];
export type OrdreDeTravailSubmissionStatusRecord = components['schemas']['OrdreDeTravailSubmissionInformationRecord'];

export type GetSaleRateRequest = {
  ordreDeTravailNumber: number;
  body: components['schemas']['GetSaleRateRequestBody'];
};
export type GetSaleRateRecord = components['schemas']['GetSaleRateRecord'];

export type SubmitAllValidationRequest = {
  otNumber: string;
};

export type SubmitValidationRequest = {
  otNumber: string;
  input: components['schemas']['SubmitValidationRequestInput'];
};

export type ContractAgreementSubmissionInput = components['schemas']['ContractAgreementSubmissionInput'][];

export type SubmitValidationRecord = components['schemas']['SubmitValidationRecord'];
export type SubmitValidationErrorRecord = components['schemas']['SubmitValidationErrorRecord'];

export type UpdateCodeDeFacturationInput = components['schemas']['UpdateCodeDeFacturationInput'];

export type AdditionalHoursRecord = components['schemas']['AdditionalHoursRecord'];
export const PreparationEnumValues: components['schemas']['PreparationEnum'][] = ['none', 'oneHour', 'oneAndAQuarterHour'];
export const DistanceTravelledEnumValues: DistanceRangeEnum[] = [
  'none',
  'range49To72',
  'range73To88',
  'range89To119',
  'range120AndMore',
  'siteTransfer',
];
export type PreparationEnum = components['schemas']['PreparationEnum'];
export type DistanceRangeEnum = components['schemas']['DistanceRange'];
export type ServiceHourRecord = components['schemas']['ServiceHourRecord'];
export type mechanicalFailuresRecord = components['schemas']['TimePeriodSourceWithComment'];
export type AdditionalTimeType = components['schemas']['AdditionalTimeType'];
export type ServiceHoursType = components['schemas']['ServiceHoursType'];
export type WorkForceTypesRecord = components['schemas']['WorkForceTypeRecord'];
export type UpdateContractAgreementAccessoryRecord = components['schemas']['UpdateContractAgreementAccessoryInput'];
export type ContractAgreementAccessoryRecord = components['schemas']['ContractAgreementAccessoryRecord'];

export type DailyTimeSheetTimeDistributionStatus = components['schemas']['DailyTimeSheetTimeDistributionStatus'];
export type DailyTimeSheetEntryStatus = { isCompleted: boolean; isValidated: boolean; isSubmitted: boolean; hasSubmissionError: boolean };

export enum DailyTimeSheetTimeDistributionStatusEnum {
  Automatic = 'automatic',
  Modified = 'modified',
  Expired = 'expired',
}

export type SalesBranches = components['schemas']['GetAllBranchesRecord'][];

export type CraneOperatorRecord = components['schemas']['CraneOperatorRecord'];

export type NonRecurringBillingRecord = components['schemas']['OrdreDeTravailServiceCallOrdreDeTravailNonRecurringBillingRecord'];
export type NonRecurringBillingInput = components['schemas']['UpdateOrdreDeTravailServiceCallOrdreDeTravailNonRecurringBillingInput'];

export type ServiceCallContinuityInformationRecord = components['schemas']['OrdreDeTravailServiceCallOrdreDeTravailInformationRecord'];
export type OrdreDeTravailContinuityInformationRecord = components['schemas']['OrdreDeTravailContinuityInformationRecord'];

export enum ContractAgreementSections {
  CraneService = 'craneService',
  NoBreak = 'noBreak',
  NoLunch = 'noLunch',
  ManTime = 'manTime',
  ManTransport = 'manTransport',
  MechanicalFailure = 'mechanicalFailure',
  Meal = 'meal',
  Preparation = 'preparation',
  Taxi = 'taxi',
  Parking = 'parking',
  CarAllowance = 'carAllowance',
  TravelExpenses49To72Km = 'travelExpenses49To72Km',
  TravelExpenses73To88Km = 'travelExpenses73To88Km',
  TravelExpenses89To119Km = 'travelExpenses89To119Km',
  TravelExpenses120AndMoreKm = 'travelExpenses120AndMoreKm',
  RoomAndBoardDay = 'roomAndBoardDay',
  RoomAndBoardMoreThanTwoWeeks = 'roomAndBoardMoreThanTwoWeeks',
}

export type UpdateAutomationRuleRequestInput = components['schemas']['UpdateAutomationRuleRequestInput'];

export type ValidationTagRecord = components['schemas']['ValidationTagRecord'];
export type ValidationTagKind = components['schemas']['ValidationTagKind'];
export enum ValidationTagKindEnum {
  WaitingForInformation = 'waitingForInformation',
  ReturnForCorrection = 'returnForCorrection',
  SubmittedWithErrors = 'submittedWithErrors',
}

export type OrdreDeTravailValidationComplexity = components['schemas']['OrdreDeTravailValidationComplexity'];
export type OrdreDeTravailValidationComplexityUpdatedRecord = {
  id: string;
  validationComplexity: OrdreDeTravailValidationComplexity;
};

export enum OrdreDeTravailValidationComplexityNumberValue {
  simple = 1,
  complex = 5,
}

export enum OrdreDeTravailValidationComplexityEnum {
  Simple = 'simple',
  Complex = 'complex',
}
