import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const PageNotFound = () => {
  const { t } = useTranslation('common');
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
      }}>
      <Typography sx={{ fontSize: 60, fontWeight: '600' }}>404</Typography>
      <Typography variant='h6'>{t('pageNotFound')}</Typography>
    </Box>
  );
};
