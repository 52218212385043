import { UseMutationOptions, UseMutationResult, useMutation } from '@tanstack/react-query';
import { ValidationError } from '../../contractAgreement/hooks/shared/useUpdateContractAgreement';
import axios, { AxiosError, AxiosResponse } from 'axios';

export const useSetEntering = (
  props?: UseMutationOptions<AxiosResponse, AxiosError<ValidationError>, string>,
): UseMutationResult<AxiosResponse, AxiosError<ValidationError>, string> => {
  return useMutation({
    ...props,
    mutationFn: async (ordreDeTravailId: string): Promise<AxiosResponse> => {
      return axios.patch<ValidationError, AxiosResponse>(`/ordre-de-travail/${ordreDeTravailId}/set-entering`);
    },
  });
};
