import { Box } from '@mui/material';

type Props = { children: React.ReactNode };

export function ListPageLayout({ children }: Props): JSX.Element {
  return (
    <Box height='100%' display='flex' flexDirection='column'>
      {children}
    </Box>
  );
}
