import { DefinedInitialDataOptions, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { WorkKindRulesResult } from '../models/workKindsModel';
import { gridRefreshIntervalInMilliseconds } from '../../../common/helpers/DatagridHelpers';

const staleTime = 1000 * 60 * 2;
type QueryParams = { take?: number; skip?: number | undefined; orderBy?: string | undefined };
export const workKindsQueryOptions = (
  params?: QueryParams,
): Pick<DefinedInitialDataOptions<WorkKindRulesResult>, 'queryKey' | 'queryFn' | 'staleTime' | 'refetchInterval'> => ({
  queryKey: ['fetchWorkKinds'],
  queryFn: async () => axios.get<WorkKindRulesResult>(`/work-kinds`, { params }).then((response) => response.data),
  staleTime: staleTime,
  refetchInterval: gridRefreshIntervalInMilliseconds,
});

export const useFetchWorkKinds = (params?: QueryParams): UseQueryResult<WorkKindRulesResult, Error> => {
  return useQuery({ ...workKindsQueryOptions(params) });
};
