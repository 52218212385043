import { useFlag } from '@unleash/proxy-client-react';
import equal from 'fast-deep-equal';
import { useSnackbar } from 'notistack';
import { RefObject, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { FieldErrors, FieldValues, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Path } from '../../paths';
import { useSetEntering } from '../../validationNext/hooks/useSetEntering';
import { ValidationParams } from '../../validationNext/params';
import { useInvalidateQuery } from './useInvalidateQuery';

export type FormSubmitHandlerOnValid<TFieldValues extends FieldValues, TTransformedValues extends FieldValues = TFieldValues> =
  | SubmitHandler<TTransformedValues>
  | ((data: TFieldValues, event?: React.BaseSyntheticEvent, skipDirtyCheck?: boolean) => unknown | Promise<unknown>);

type FormSubmitHandler<TFieldValues extends FieldValues, TTransformedValues extends FieldValues = TFieldValues> = (
  onValid: FormSubmitHandlerOnValid<TFieldValues, TTransformedValues>,
  onInvalid?: (errors: FieldErrors<TFieldValues>, event?: React.BaseSyntheticEvent, data?: TFieldValues) => void,
  onPrevent?: () => void,
) => (e?: React.BaseSyntheticEvent) => Promise<void>;

export interface FormBinderRef {
  bindFormSubmit: <T extends FieldValues>(
    formKind: FormKind,
    handleSubmit: FormSubmitHandler<T>,
    onValid: (data: T) => Promise<void | boolean>,
    defaultValues: T | undefined,
  ) => void;
  bindSubmitAll: (submitAll: () => Promise<void>) => void;
  bindValidate: (formKind: FormKind, validateFn: () => Promise<void>) => void;
  bindFormStateDisabled: (formKind: FormKind, disabled: boolean) => void;
  bindReset: (formKind: FormKind, reset: () => void) => void;
  unbindFormSubmit: (formKind: FormKind) => void;
  unbindFormStateDisabled: (formKind: FormKind) => void;
  unbindSubmitAll: () => void;
  unbindValidate: (formKind: FormKind) => void;
  unbindReset: (formKind: FormKind) => void;
}

const defaultActionsRef: FormBinderRef = {
  bindFormSubmit: () => null,
  bindSubmitAll: () => null,
  bindValidate: () => null,
  bindFormStateDisabled: () => null,
  bindReset: () => null,
  unbindFormSubmit: () => null,
  unbindFormStateDisabled: () => null,
  unbindSubmitAll: () => null,
  unbindValidate: () => null,
  unbindReset: () => null,
};

export type FormBinder = {
  ref: RefObject<FormBinderRef>;
  reset: (kind: FormKind) => void;
  validate: () => Promise<void>;
  formSubmit: (context: SubmitContext) => Promise<boolean>;
  submitAll: () => Promise<void>;
  formSaveAndContinueDisabled: boolean;
  formSaveDisabled: boolean;
  formSaveLoading: boolean;
  formSaveAndContinueLoading: boolean;
};

type FormStatus = {
  disabled?: boolean;
};

export type SubmitContext = 'save' | 'continue' | 'saveAll';
type FormKind =
  | 'contractAgreement'
  | 'automationRule'
  | 'timeSheet'
  | 'prefacturation'
  | 'nonRecurringBilling'
  | 'caNumber'
  | 'ordreDeTravail'
  | 'workKindRule';
type SubmitResult = 'validation' | 'skip' | 'success' | 'error' | 'prevent';

export const useFormBinderRef = (): FormBinder => {
  const ref = useRef<FormBinderRef>(defaultActionsRef);
  const { t } = useTranslation('common');
  const [[submittingSave, submittingContinue], setSubmitting] = useState<[boolean, boolean]>([false, false]);
  const [formDisabledRecord, setFormDisabledRecord] = useState<Record<FormKind, FormStatus>>({} as Record<FormKind, FormStatus>);
  const submitAllRef = useRef<() => Promise<void>>(() => Promise.resolve());
  const formSubmitRecordRef = useRef<Record<FormKind, (context: SubmitContext) => Promise<SubmitResult>>>(
    {} as Record<FormKind, (context: SubmitContext) => Promise<SubmitResult>>,
  );
  const validateRecordRef = useRef<Record<FormKind, () => Promise<void>>>({} as Record<FormKind, () => Promise<void>>);
  const resetRecordRef = useRef<Record<FormKind, () => void>>({} as Record<FormKind, () => void>);
  const inSequence = useFlag('web_validation_save_in_sequence');
  const params = useParams<ValidationParams>();
  const { mutateAsync: mutateEntering } = useSetEntering();
  const { invalidate } = useInvalidateQuery();

  const { enqueueSnackbar } = useSnackbar();
  const bindFormStateDisabledHandler = useCallback((formKind: FormKind, disabled: boolean) => {
    setFormDisabledRecord((prev) => ({ ...prev, [formKind]: { ...prev[formKind], disabled } }));
  }, []);

  const bindFormSubmitHandler = useCallback(
    <T extends FieldValues>(
      formName: FormKind,
      handleSubmit: FormSubmitHandler<T>,
      onValid: (data: T) => Promise<void | boolean>,
      defaultValues: T | undefined,
    ) => {
      formSubmitRecordRef.current[formName] = async (context) =>
        new Promise<SubmitResult>(async (resolve) => {
          const onValidSave = async (data: T) => {
            onValid(data)
              // note: to use "prevent" and prevent to enqueue a new snackbar
              // make sure your "onValid" function always return false
              // wrap it with a try catch finally block
              .then((autoHandled = true) => (autoHandled ? resolve('success') : resolve('prevent')))
              .catch(() => resolve('error'));
          };

          return await handleSubmit(
            async (data: T, _?: React.BaseSyntheticEvent, skipDirtyCheck?: boolean) => {
              const dataChanged = skipDirtyCheck || (defaultValues && !equal(data, defaultValues));
              if (dataChanged) {
                return onValidSave(data);
              }
              resolve('skip');
            },
            (errors, _event, data) => {
              // note: We need to save the form even if there are only min quantity errors
              // but only if we are not in a saveAll context (submit)
              if (formName === 'prefacturation' && context !== 'saveAll' && data) {
                const hasOnlyMinQuantityErrors = Object.values(errors).every((e) => {
                  if (Array.isArray(e)) return e.every((er) => er.quantity?.type === 'min');
                  return true;
                });
                if (hasOnlyMinQuantityErrors) {
                  return onValidSave(data);
                }
                resolve('validation');
              } else {
                resolve('validation');
              }
            },
            () => {
              resolve('prevent'); // were asked to prevent showing a snackbar
            },
          )();
        });
    },
    [],
  );

  const bindSubmitAllHandler = useCallback((submitAll: () => Promise<void>) => {
    submitAllRef.current = submitAll;
  }, []);

  const unbindFormStateDisabledHandler = useCallback((formKind: FormKind) => {
    setFormDisabledRecord((prev) => {
      delete prev[formKind];
      return prev;
    });
  }, []);

  const unbindFormSubmitHandler = useCallback((formKind: FormKind) => {
    delete formSubmitRecordRef.current[formKind];
  }, []);

  const unbindSubmitAllHandler = useCallback(() => {
    submitAllRef.current = () => Promise.resolve();
  }, []);

  const bindValidateHandler = useCallback((formKind: FormKind, validateFn: () => Promise<void>) => {
    validateRecordRef.current[formKind] = validateFn;
  }, []);

  const unbindValidateHandler = useCallback((formKind: FormKind) => {
    delete validateRecordRef.current[formKind];
  }, []);

  const bindResetHandler = useCallback((formKind: FormKind, validateFn: () => void) => {
    resetRecordRef.current[formKind] = validateFn;
  }, []);

  const unbindResetHandler = useCallback((formKind: FormKind) => {
    delete resetRecordRef.current[formKind];
  }, []);

  const validate = useCallback(async () => {
    try {
      setSubmitting((prev) => [prev[0], true]);
      for (const valid of Object.values(validateRecordRef.current)) {
        await valid();
      }
    } catch {
      enqueueSnackbar(t('saveResult.save', { context: 'error' }), { variant: 'error' });
    }
    await invalidate([Path.ValidationsPage]);
    setSubmitting((prev) => [prev[0], false]);
  }, [enqueueSnackbar, invalidate, t]);

  useImperativeHandle(
    ref,
    () => ({
      bindFormSubmit: bindFormSubmitHandler,
      bindFormStateDisabled: bindFormStateDisabledHandler,
      bindSubmitAll: bindSubmitAllHandler,
      bindValidate: bindValidateHandler,
      bindReset: bindResetHandler,
      unbindFormSubmit: unbindFormSubmitHandler,
      unbindFormStateDisabled: unbindFormStateDisabledHandler,
      unbindSubmitAll: unbindSubmitAllHandler,
      unbindValidate: unbindValidateHandler,
      unbindReset: unbindResetHandler,
    }),
    [
      bindFormStateDisabledHandler,
      bindFormSubmitHandler,
      bindResetHandler,
      bindSubmitAllHandler,
      bindValidateHandler,
      unbindFormStateDisabledHandler,
      unbindFormSubmitHandler,
      unbindResetHandler,
      unbindSubmitAllHandler,
      unbindValidateHandler,
    ],
  );

  const onSubmitting = useCallback((context: SubmitContext, value?: boolean) => {
    setSubmitting((prev) => {
      if (context === 'save' || context === 'saveAll') {
        return [value ?? !prev[0], prev[1]];
      } else if (context === 'continue') {
        return [prev[0], value ?? !prev[1]];
      }
      return prev;
    });
  }, []);

  const handleSubmit = useCallback(
    async (context: SubmitContext): Promise<boolean> => {
      onSubmitting(context, true);
      if (params.ordreDeTravailId) {
        await mutateEntering(params.ordreDeTravailId);
      }

      const results: SubmitResult[] = [];
      if (inSequence) {
        for (const submit of Object.values(formSubmitRecordRef.current)) {
          const result = await submit(context);
          results.push(result);
        }
      } else {
        results.push(
          ...(await Promise.all(
            Object.values(formSubmitRecordRef.current).map(async (submit) => {
              return submit(context);
            }),
          )),
        );
      }

      const pickResult = pickMostImportantSubmitResult(results);
      const severity = pickResult === 'skip' ? 'default' : pickResult === 'validation' ? 'warning' : pickResult;
      const isSkippingContinue = pickResult === 'skip' && context === 'continue';
      const isSkippingSubmitAll = pickResult === 'skip' && context === 'saveAll';
      if (severity !== 'prevent' && !isSkippingContinue && !isSkippingSubmitAll) {
        enqueueSnackbar(t('saveResult.save', { context: pickResult, ns: 'common' }), { variant: severity });
      }
      onSubmitting(context, false);
      if (context === 'continue') {
        await validate();
      } else {
        await invalidate([Path.ValidationsPage, params.ordreDeTravailId]);
      }
      return ['success', 'skip'].includes(pickResult);
    },
    [enqueueSnackbar, inSequence, invalidate, mutateEntering, onSubmitting, params.ordreDeTravailId, t, validate],
  );

  const handleSubmitAll = useCallback(async () => {
    setSubmitting([false, true]);
    await submitAllRef.current().finally(() => {
      setSubmitting([false, false]);
    });
    await invalidate([Path.ValidationsPage, params.ordreDeTravailId]);
  }, [invalidate, params.ordreDeTravailId]);

  const handleReset = useCallback((kind: FormKind) => {
    if (resetRecordRef.current[kind]) {
      resetRecordRef.current[kind]();
    }
  }, []);

  const isInError = Object.values(formDisabledRecord).some((record) => record.disabled);
  const saveAndContinueDisabled = submittingContinue || isInError;
  const saveDisabled = submittingSave || !Object.keys(formSubmitRecordRef.current).length;

  return useMemo(
    () => ({
      ref,
      validate: validate,
      formSubmit: handleSubmit,
      submitAll: handleSubmitAll,
      reset: handleReset,
      formSaveAndContinueDisabled: saveAndContinueDisabled,
      formSaveDisabled: saveDisabled,
      formSaveLoading: submittingSave,
      formSaveAndContinueLoading: submittingContinue,
    }),
    [handleSubmit, handleSubmitAll, saveAndContinueDisabled, submittingContinue, submittingSave, saveDisabled, validate, handleReset],
  );
};

function pickMostImportantSubmitResult(values: SubmitResult[]): SubmitResult {
  const priorityOrder = ['prevent', 'error', 'validation', 'success', 'skip'] as SubmitResult[];
  return priorityOrder.find((value) => values.includes(value))!;
}
