import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';
import { AutomationRuleRecord } from '../../models/automatisationModel';
import { AutomationRuleFormDataDto } from '../AutomationRuleForm';

type CreateAutomationRuleRequest = {
  input: AutomationRuleFormDataDto;
};

type UseCreateAutomationRuleResult = {
  createAutomationRule: (request: CreateAutomationRuleRequest) => Promise<AxiosResponse<AutomationRuleRecord>>;
  isLoading: boolean;
};

export const useCreateAutomationRule = (): UseCreateAutomationRuleResult => {
  const [isLoading, setIsLoading] = useState(false);

  const createAutomationRule = async ({ input }: CreateAutomationRuleRequest): Promise<AxiosResponse<AutomationRuleRecord>> => {
    setIsLoading(true);
    const response = await axios.post<AutomationRuleRecord, AxiosResponse<AutomationRuleRecord>>(`/automation-rules`, input);
    setIsLoading(false);
    return response;
  };

  return { createAutomationRule, isLoading };
};
