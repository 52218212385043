import axios from 'axios';
import { DefinedInitialDataOptions, QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import { Path } from '../../paths';
import { GetCATimesheetRecord } from '../models/models';
import { DailyTimesheetLoaderData } from '../loaders/timesheetLoader';
import { useLoaderData } from 'react-router-dom';

export interface getCADailyTimesheetProps {
  dailyTimeSheetId: string | undefined;
  contractAgreementId: string | undefined;
}

export const getCATimesheetKeys = ({ dailyTimeSheetId, contractAgreementId }: getCADailyTimesheetProps): QueryKey => [
  Path.ValidationsPage,
  Path.TimesheetsPage,
  contractAgreementId,
  dailyTimeSheetId,
];

export const caDailyTimeSheetQueryOptions = ({
  dailyTimeSheetId,
  contractAgreementId,
}: getCADailyTimesheetProps): Pick<DefinedInitialDataOptions<GetCATimesheetRecord>, 'queryKey' | 'queryFn' | 'staleTime' | 'gcTime'> => ({
  queryKey: getCATimesheetKeys({ dailyTimeSheetId, contractAgreementId }),
  queryFn: async () => {
    return axios
      .get<GetCATimesheetRecord>(`/contract-agreements/${contractAgreementId}/daily-time-sheet/${dailyTimeSheetId}`)
      .then((response) => response.data);
  },
  staleTime: 0,
  gcTime: 0,
});

export const useGetCADailyTimesheet = ({
  dailyTimeSheetId,
  contractAgreementId,
}: getCADailyTimesheetProps): UseQueryResult<GetCATimesheetRecord, unknown> => {
  const { csDailyTimeSheet } = useLoaderData() as DailyTimesheetLoaderData;
  return useQuery({
    ...caDailyTimeSheetQueryOptions({ dailyTimeSheetId, contractAgreementId }),
    initialData: csDailyTimeSheet?.data,
    enabled: !!dailyTimeSheetId && !!contractAgreementId,
  });
};
