import { useMemo, useRef } from 'react';

export const useTableRowCount = (queryCount?: number): number => {
  const rowCountRef = useRef(queryCount || 0);

  return useMemo(() => {
    if (queryCount !== undefined) {
      rowCountRef.current = queryCount;
    }
    return rowCountRef.current;
  }, [queryCount]);
};
