import { FetchQueryOptions, useQuery, UseQueryResult } from '@tanstack/react-query';
import { OrdreDeTravailInfoRecord } from '../models/models';
import axios, { AxiosError } from 'axios';
import { Path } from '../../paths';

export const ordreDeTravailInfoQueryOptions = (id: string | undefined): FetchQueryOptions<OrdreDeTravailInfoRecord, AxiosError> => ({
  queryKey: [Path.ValidationsPage, id, 'info'],
  queryFn: async () => axios.get<OrdreDeTravailInfoRecord>(`/ordre-de-travail/${id}/info`).then((response) => response.data),
  staleTime: 0,
  gcTime: 0,
});

export const useGetOrdreDeTravailInfo = (
  id: string | undefined,
  initialData?: OrdreDeTravailInfoRecord,
): UseQueryResult<OrdreDeTravailInfoRecord, AxiosError> => {
  return useQuery<OrdreDeTravailInfoRecord, AxiosError>({
    ...ordreDeTravailInfoQueryOptions(id),
    initialData,
    enabled: !!id,
  });
};
