import { white } from '../common/constants/colors';

export const noBorders = {
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: white,
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'visible',
  },
};

export const noBordersAndPointer = {
  ...noBorders,
  '& .MuiDataGrid-cell:hover': {
    cursor: 'pointer',
  },
};
