import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Warning from '@mui/icons-material/Warning';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { enUS, frFR } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import { Flags } from './Flags';
import { RequestClients } from './RequestClients';
import { Router } from './Router';
import SignoutPage from './SignoutPage';
import { AbilityProvider } from './authorization/AbilityProvider';
import { loadSchedulerLocales, setSchedulerLocale } from './bryntum/localization/schedulerLocales';
import EmployeeContextProvider from './common/contexts/EmployeeContext';
import { DispatchingAlertSnackbar } from './dispatch/components/Snackbar/DispatchingAlertSnackbar';
import muiTheme from './mui/theme';
import { Maintenance } from './Maintenance';

export const App = (): JSX.Element | null => {
  const {
    i18n: { resolvedLanguage },
  } = useTranslation('layout');
  const langCode = resolvedLanguage === 'fr' ? frFR : enUS;
  const themeWithLocale = React.useMemo(() => createTheme(muiTheme, langCode), [langCode]);

  useEffect(() => {
    loadSchedulerLocales();
  }, []);

  useEffect(() => {
    setSchedulerLocale(resolvedLanguage);
  }, [resolvedLanguage]);

  return (
    <ThemeProvider theme={themeWithLocale}>
      <RequestClients>
        <CssBaseline />
        <AuthenticatedTemplate>
          <EmployeeContextProvider>
            <AbilityProvider>
              <Flags>
                <SnackbarProvider
                  maxSnack={10}
                  iconVariant={{
                    error: <Warning />,
                  }}
                  Components={{ dispatchingAlert: DispatchingAlertSnackbar }}>
                  <Maintenance>
                    <Router />
                  </Maintenance>
                </SnackbarProvider>
              </Flags>
            </AbilityProvider>
          </EmployeeContextProvider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <SignoutPage />
        </UnauthenticatedTemplate>
      </RequestClients>
    </ThemeProvider>
  );
};
