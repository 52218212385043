import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '../../authorization/Ability';
import { Role } from '../../common/models/models';

const editTimesheetRoles: Role[] = ['dispatcher', 'admin', 'billingClerk', 'dispatchChief'];
const viewTimesheetRoles: Role[] = editTimesheetRoles.concat('validationReadOnly');
const submitTimesheetRoles: Role[] = editTimesheetRoles;
export const timesheetAbility = 'timesheet';

export const defineTimesheetAbilities = (abilityBuilder: AbilityBuilder<AppAbility>, roles: Role[] | undefined): void => {
  const { can } = abilityBuilder;

  if (roles?.some((role: Role) => viewTimesheetRoles.includes(role))) can('view', timesheetAbility);
  if (roles?.some((role: Role) => submitTimesheetRoles.includes(role))) can('submit', timesheetAbility);
  if (roles?.some((role: Role) => editTimesheetRoles.includes(role))) can('edit', timesheetAbility);
};
