import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback } from 'react';
import { Button, Card, Grid2 as Grid, IconButton, Stack, SxProps, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { DispatchingAlertCreatedRecord } from '../../Models/models';
import { black, defaultDarkColor, white } from '../../../common/constants/colors';
import { useTranslation } from 'react-i18next';
import { getAlertIcon } from '../Common/dispatchingAlertHelpers';
import { formatDateLong, isoDateOnlyToDate } from '../../../common/helpers/DateTimeHelper';
import i18n from '../../../i18n';
import PubSub from 'pubsub-js';
import { HIGHLIGHT_DISPATCHING_ALERT_EVENT, PubSubHighlightDispatchingAlertEvent } from '../Types/pubsubEvents';

interface Props extends CustomContentProps {
  dispatchingAlert: DispatchingAlertCreatedRecord;
}

export const formatDispatchingAlertId = (dispatchingAlert: DispatchingAlertCreatedRecord) =>
  `${dispatchingAlert.type}-${dispatchingAlert.serviceCallId}`;

export const isDispatchingAlertSnackbarDisplayed = (dispatchingAlert: DispatchingAlertCreatedRecord) =>
  !!document.getElementById(formatDispatchingAlertId(dispatchingAlert));

export const DispatchingAlertSnackbar = forwardRef<HTMLDivElement, Props>(({ id, dispatchingAlert }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const { t } = useTranslation('dispatch');
  const alertMessage = i18n.language === 'fr' ? dispatchingAlert.frenchMessage : dispatchingAlert.englishMessage;

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const handleSeeButtonClick = () =>
    PubSub.publish(HIGHLIGHT_DISPATCHING_ALERT_EVENT, {
      dispatchingAlert,
    } as PubSubHighlightDispatchingAlertEvent);

  return (
    <SnackbarContent ref={ref} id={formatDispatchingAlertId(dispatchingAlert)}>
      <Card sx={styles.card}>
        <Grid container sx={styles.container}>
          <Grid size={{ xs: 1.5 }} sx={styles.iconContainer}>
            {getAlertIcon(dispatchingAlert.type, dispatchingAlert.severity)}
          </Grid>
          <Grid size={{ xs: 8.5 }}>
            <Stack direction='column'>
              <Typography sx={styles.date}>{formatDateLong(isoDateOnlyToDate(dispatchingAlert.date), i18n.resolvedLanguage)}</Typography>
              <Typography sx={styles.message}>{alertMessage}</Typography>
            </Stack>
          </Grid>
          <Grid size={2} sx={styles.actionsContainer}>
            <Stack direction='row'>
              <Button sx={styles.highlightButton} variant='outlined' onClick={handleSeeButtonClick}>
                {t('alert.see')}
              </Button>
              <IconButton sx={styles.dismissButton} onClick={handleDismiss}>
                <Close />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </SnackbarContent>
  );
});

const styles: { [key: string]: SxProps } = {
  card: {
    backgroundColor: defaultDarkColor,
    width: '600px',
  },
  container: { marginY: '8px', marginRight: '16px', width: 'unset' },
  date: {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: 10,
    color: white,
  },
  message: {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: 14,
    color: white,
  },
  iconContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  actionsContainer: { display: 'flex', alignItems: 'center', justifyContent: 'end' },
  highlightButton: {
    backgroundColor: defaultDarkColor,
    textTransform: 'uppercase',
    borderRadius: '4px',
    borderWidth: 0,
    minWidth: 0,
    paddingX: 1,
    color: white,
    '&:hover': {
      backgroundColor: black,
      borderWidth: 0,
    },
  },
  dismissButton: {
    backgroundColor: defaultDarkColor,
    color: white,
    '&:hover': {
      backgroundColor: black,
    },
  },
};
