import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { useLayoutEffect, useState } from 'react';

type Props = {
  isLoading: boolean;
  component?: React.ElementType;
} & ButtonProps;

const MINIMAL_LOADING_TIME_MS = 300;

// https://mui.com/material-ui/react-button/#loading-button
export function LoadingButton({ isLoading, disabled: isDisabled, ...rest }: Props) {
  const [loading, setLoading] = useState(isLoading);

  useLayoutEffect(() => {
    const handler = setTimeout(() => {
      setLoading(isLoading);
    }, MINIMAL_LOADING_TIME_MS);
    return () => {
      clearTimeout(handler);
    };
  }, [isLoading]);

  const disabled = loading || isDisabled;

  const hasNoIcon = rest.startIcon === undefined && rest.endIcon === undefined;
  const isStartIcon = rest.startIcon !== undefined && rest.endIcon === undefined;
  const Loader = (
    <CircularProgress
      size={loading ? 20 : 0}
      data-testid='loading-spinner'
      aria-hidden={!loading}
      thickness={5}
      sx={{
        p: loading ? 0.2 : 0,
        opacity: loading ? 0.2 : 0,
        filter: `saturate(${disabled ? 0 : 1})`,
        transition: 'width 0.3s',
      }}
    />
  );
  const props = {
    ...rest,
    disabled,
    startIcon: loading && isStartIcon ? Loader : rest.startIcon,
    endIcon: hasNoIcon || (loading && !isStartIcon) ? Loader : rest.endIcon,
  };
  return (
    <Button {...props} sx={{ ...(rest.sx || {}), textTransform: 'uppercase' }}>
      {rest.children}
    </Button>
  );
}
