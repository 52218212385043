import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '../../authorization/Ability';
import { Role } from '../../common/models/models';

const returnForCorrectionRoles: Role[] = ['billingClerk', 'admin'];
const editValidationRoles: Role[] = ['admin', 'billingClerk', 'dispatchChief', 'dispatcher'];
const viewValidationRoles: Role[] = editValidationRoles.concat('validationReadOnly');

export const validationAbility = 'validation';
export const returnForCorrectionAbility = 'returnForCorrection';

export const defineValidationAbilities = (abilityBuilder: AbilityBuilder<AppAbility>, roles: Role[] | undefined): void => {
  const { can } = abilityBuilder;

  if (!!roles?.some((role) => editValidationRoles.includes(role))) can('edit', validationAbility);

  if (!!roles?.some((role) => viewValidationRoles.includes(role))) can('view', validationAbility);

  if (!!roles?.some((role) => returnForCorrectionRoles.includes(role))) can('view', returnForCorrectionAbility);
};
