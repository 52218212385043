import { RestartAlt as RestartAltIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PubSub from 'pubsub-js';

type Props = {
  resetSortAndFilters: () => void;
};

export const RESET_GRID_EXTERNAL_FILTERS = Symbol('RESET_GRID_EXTERNAL_FILTERS');

export const ResetSortAndFilterButton = ({ resetSortAndFilters }: Props): JSX.Element => {
  const { t } = useTranslation('common');

  const resetSortsAndFilters = useCallback(() => {
    resetSortAndFilters();
    PubSub.publish(RESET_GRID_EXTERNAL_FILTERS);
  }, [resetSortAndFilters]);

  return (
    <Button color='inherit' startIcon={<RestartAltIcon />} sx={{ textTransform: 'uppercase', ml: 'auto' }} onClick={resetSortsAndFilters}>
      {t('commonTableToolbar.resetSortAndFiltersButton')}
    </Button>
  );
};
