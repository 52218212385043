import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';

export const useInvalidateQuery = () => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const callback = useCallback(
    async (key: QueryKey) => {
      try {
        setIsLoading(true);
        const invalidate = queryClient.invalidateQueries({ queryKey: key });
        const refetch = queryClient.refetchQueries({ queryKey: key });
        await Promise.all([invalidate, refetch]);
      } finally {
        setIsLoading(false);
      }
    },
    [queryClient],
  );

  return { invalidate: callback, isLoading };
};
