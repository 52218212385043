import { Chip } from '@mui/material';
import {
  DataGridPremium,
  GridCallbackDetails,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowParams,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { enUS, frFR } from '@mui/x-data-grid-premium/locales';
import { TFunction, i18n as Typei18b } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getDefaultColumnVisibilityModel, useSaveGridState } from '../../common/helpers/DatagridHelpers';
import { HeaderInfo } from '../../common/types/table/HeaderInfo';
import { noBordersAndPointer } from '../../mui/muiGridStyle';
import { Path } from '../../paths';
import { WorkKindRuleListItemRecord } from './models/workKindsModel';
import { GridFooter } from '../../common/components/table/GridFooter';
import { CommonTableToolbar } from '../../common/components/table/CommonTableToolbar';
import { GridColumnVisibilityModel } from '../../common/helpers/DataGridPremiumHelpers';

type Props = {
  workKinds: WorkKindRuleListItemRecord[];
  isLoading: boolean;
  count: number;
  sort: GridSortModel;
  paginationModel?: GridPaginationModel;
  handlePaginationModelChange?: (model: GridPaginationModel, details: GridCallbackDetails) => void;
  onSortModelChange: (sortModel: GridSortModel) => void;
  isRefetching: boolean;
  lastUpdatedAt: Date;
};

export const gridName = 'workKindsTable';

export const WorkKindsTable = ({
  workKinds,
  lastUpdatedAt,
  count,
  sort,
  paginationModel,
  handlePaginationModelChange,
  onSortModelChange,
  isRefetching,
  isLoading,
}: Props): JSX.Element => {
  const ref = useGridApiRef();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('configuration', { keyPrefix: 'workKinds' });
  const dataGridLocalization = i18n.resolvedLanguage === 'fr' ? frFR : enUS;
  const [getColumns, resetColumns, resetSortAndFilters] = useSaveGridState(ref, gridName);

  const headersInformation = useMemo(() => getHeadersInformation(t, i18n), [t, i18n]);
  const tableColumns = [...getColumns<WorkKindRuleListItemRecord>(t, headersInformation)];

  const defaultColumnVisibilityModel: GridColumnVisibilityModel = useMemo<GridColumnVisibilityModel>(
    () => getDefaultColumnVisibilityModel<WorkKindRuleListItemRecord>(headersInformation),
    [headersInformation],
  );

  return (
    <DataGridPremium
      sx={noBordersAndPointer}
      rowSpacingType='border'
      apiRef={ref}
      rows={workKinds}
      columns={tableColumns}
      localeText={dataGridLocalization.components.MuiDataGrid.defaultProps.localeText}
      disableAggregation
      disableRowGrouping
      disableColumnFilter
      disableRowSelectionOnClick
      loading={isLoading}
      paginationMode='server'
      paginationModel={paginationModel}
      onSortModelChange={onSortModelChange}
      onPaginationModelChange={handlePaginationModelChange}
      slotProps={{
        toolbar: {
          headersInformation,
          resetColumns,
          resetSortAndFilters,
        },
        footer: {
          isRefetching,
          lastUpdatedAt,
        },
      }}
      sortModel={sort}
      rowCount={count}
      slots={{
        toolbar: CommonTableToolbar,
        footer: GridFooter,
      }}
      sortingMode='server'
      initialState={{
        columns: {
          columnVisibilityModel: defaultColumnVisibilityModel,
        },
      }}
      onRowClick={({ row: { id } }: GridRowParams) => navigate(`/${Path.WorkKindsPage}/${id}`)}
    />
  );
};

const getHeadersInformation = (t: TFunction<'configuration', 'workKinds'>, i18n: Typei18b): HeaderInfo<WorkKindRuleListItemRecord>[] => {
  return [
    {
      field: 'workKind',
      label: 'title',
      type: 'string',
      sortable: true,
      renderCell: (props: GridRenderCellParams<WorkKindRuleListItemRecord, string>) => {
        const isFr = i18n.languages[0] === 'fr';
        const code = props.row.code ?? '';
        const name = isFr ? props.row.nameFr : props.row.nameEn;
        return `${code} - ${name ?? ''}`;
      },
    },
    {
      field: 'isActive',
      label: 'status',
      type: 'string',
      sortable: true,
      renderCell: (props: GridRenderCellParams<WorkKindRuleListItemRecord, number>) => (
        <Chip color={props.value ? 'success' : 'default'} label={props.value ? t('active') : t('inactive')} />
      ),
    },
    {
      field: 'description',
      label: 'description',
      type: 'string',
      sortable: true,
      renderCell: (props: GridRenderCellParams<WorkKindRuleListItemRecord, string>) => props.value,
    },
  ];
};
