import axios, { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { AutomationRuleRecord } from '../../models/automatisationModel';

export function useFetchAutomationRule(automationRuleId?: string) {
  const [data, setData] = useState<AutomationRuleRecord | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get<AutomationRuleRecord>(`/automation-rules/${automationRuleId}`);
        setData(response.data);
      } catch (err: unknown) {
        if (axios.isAxiosError(err)) {
          const axiosError = err as AxiosError;
          setError(axiosError.message);
        } else {
          console.error(err);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [automationRuleId]);

  return { data, isLoading, error };
}
