import { Grid2 as Grid, Stack, SxProps, Tab, Tabs, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ListPageLayout } from '../../common/components/container/ListPageLayout';
import { MainCard } from '../../common/components/container/MainCard';
import tableTitleStyle from '../../common/components/table/TableTitleStyle';
import { Path } from '../../paths';
import { WorkKindsRulePage } from './WorkKindsRulePage';
import { AutomationRulesPage } from './automationRules/AutomationRulesPage';
import { ActionButton } from '../../common/components/button/ActionButton';

export enum WorkKindsPageEnum {
  WorkindsList = 'workindsList',
  Automatisation = 'automation',
}

export const WorkKindsPage = (): JSX.Element | null => {
  const { t } = useTranslation('configuration');
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams({ tab: WorkKindsPageEnum.WorkindsList });
  const mainTabSelection = (searchParams.get('tab') as WorkKindsPageEnum) ?? WorkKindsPageEnum.WorkindsList;

  const handleTabChange = useCallback(
    (_: React.SyntheticEvent, newValue: WorkKindsPageEnum) => {
      setSearchParams((prevSearchParams) => {
        prevSearchParams.set('tab', newValue);
        return prevSearchParams;
      });
    },
    [setSearchParams],
  );

  return (
    <ListPageLayout>
      <Stack direction='row' sx={styles.headers}>
        <Stack>
          <Typography sx={tableTitleStyle} variant='h1'>
            {t('titleWorkKinds')}
          </Typography>
        </Stack>
        {mainTabSelection === WorkKindsPageEnum.Automatisation && (
          <Stack>
            <ActionButton
              color='secondary'
              label={t('create')}
              sx={{ textTransform: 'uppercase' }}
              variant='contained'
              onClick={() => {
                navigate(`${Path.AutomationRulesPage}/new`);
              }}
            />
          </Stack>
        )}
      </Stack>
      <Grid container sx={styles.tabsContainer}>
        <MainCard paddingX={0} paddingY={0}>
          <Tabs
            sx={styles.tabs}
            value={mainTabSelection}
            onChange={handleTabChange}
            textColor='primary'
            indicatorColor='primary'
            role='navigation'>
            <Tab value={WorkKindsPageEnum.WorkindsList} label={t(`tabs.${WorkKindsPageEnum.WorkindsList}`)} />
            <Tab value={WorkKindsPageEnum.Automatisation} label={t(`tabs.${WorkKindsPageEnum.Automatisation}`)} />
          </Tabs>
        </MainCard>
      </Grid>
      {mainTabSelection === WorkKindsPageEnum.WorkindsList && <WorkKindsRulePage />}
      {mainTabSelection === WorkKindsPageEnum.Automatisation && <AutomationRulesPage />}
    </ListPageLayout>
  );
};

const styles: { [key: string]: SxProps } = {
  tabsContainer: {
    paddingBottom: 2,
  },
  headers: {
    justifyContent: 'space-between',
  },
  actionButton: {
    height: 'fit-content',
  },
  tabs: {
    display: 'inline-flex',
  },
};
