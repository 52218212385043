import { ButtonTypeMap } from '@mui/material';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import { LoadingButton } from './LoadingButton';

type Props = ButtonTypeMap['props'] &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    label: string;
  };

export const ActionButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { sx = props.color ? {} : { color: 'rgba(0,0,0,0.87)' }, type, label, ...rest } = props;
  return (
    <LoadingButton {...rest} sx={sx} type={type} ref={ref} isLoading={false}>
      {label}
    </LoadingButton>
  );
});
