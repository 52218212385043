import { Box, Stack, Typography } from '@mui/material';
import { ErrorCard } from '../card/ErrorCard';
import { BackButton } from '../button/BackButton';
import { useNavigate } from 'react-router-dom';

type Props = {
  headerText?: string;
  backButtonUrl?: string;
  backButtonLabel?: string;
  title: string;
  message: string;
  contactAdmin?: boolean;
};

export const ErrorPage = ({ headerText, backButtonUrl, backButtonLabel, title, message, contactAdmin = false }: Props): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Box height='100%' display='flex' flexDirection='column'>
      <Stack direction='row' spacing={2} alignItems='center'>
        {backButtonUrl && <BackButton to={backButtonUrl} />}
        {headerText && <Typography variant='h1'>{headerText}</Typography>}
      </Stack>
      <ErrorCard
        title={title}
        content={message}
        contactAdmin={contactAdmin}
        buttonLabel={backButtonLabel}
        buttonCallback={backButtonUrl ? () => navigate(backButtonUrl) : undefined}
      />
    </Box>
  );
};
