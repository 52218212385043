import { AbilityBuilder } from '@casl/ability';
import { AppAbility } from '../../authorization/Ability';
import { Role } from '../../common/models/models';

const accessWorkKindRulesRoles: Role[] = ['admin', 'billingClerk'];

export const workKindRulesAbility = 'workKindRules';

export const defineWorkKindRulesAbilities = (abilityBuilder: AbilityBuilder<AppAbility>, roles: Role[] | undefined): void => {
  const { can } = abilityBuilder;

  if (!!roles?.some((role) => accessWorkKindRulesRoles.includes(role))) can('view', workKindRulesAbility);
};
