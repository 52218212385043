import { DefinedInitialDataOptions, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';
import { AutomationRulesResult } from '../../models/automatisationModel';
import { gridRefreshIntervalInMilliseconds } from '../../../../common/helpers/DatagridHelpers';

const staleTime = 1000 * 60 * 2;
type QueryParams = { take: number; skip: number; orderBy: string | undefined };
export const AutomationsQueryOptions = (
  params: QueryParams,
): Pick<DefinedInitialDataOptions<AutomationRulesResult>, 'queryFn' | 'queryKey' | 'staleTime' | 'refetchInterval'> => ({
  queryKey: ['fetchAutomations'],
  queryFn: async () => axios.get<AutomationRulesResult>(`/automation-rules`, { params }).then((response) => response.data),
  staleTime: staleTime,
  refetchInterval: gridRefreshIntervalInMilliseconds,
});

export const useFetchAutomationRules = (params: QueryParams): UseQueryResult<AutomationRulesResult, Error> => {
  return useQuery({ ...AutomationsQueryOptions(params) });
};
