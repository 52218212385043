// Theses values come from ../public/config.js
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const env = { ...window.env };

export const config = {
  AUTH_API_SCOPE: env.AUTH_API_SCOPE,
  API_URL: env.API_URL,
  APP_VERSION: env.APP_VERSION,
  AUTH_AUTHORITY: env.AUTH_AUTHORITY,
  AUTH_CLIENT_ID: env.AUTH_CLIENT_ID,
  AUTH_REDIRECT_URI: env.AUTH_REDIRECT_URI,
  AUTH_LOGIN_REDIRECT: env.AUTH_LOGIN_REDIRECT,
  RELEASE_DATE: env.RELEASE_DATE,
  WS_URL: env.WS_URL,
  USE_APOLLO_DEV_TOOLS: env.USE_APOLLO_DEV_TOOLS,
  SENTRY_DSN: env.SENTRY_DSN as string,
  SENTRY_RELEASE: env.SENTRY_RELEASE as string,
  SENTRY_ENVIRONMENT: env.SENTRY_ENVIRONMENT as string,
  SENTRY_TRACESSAMPLERATE: env.SENTRY_TRACESSAMPLERATE ? (+(env.SENTRY_TRACESSAMPLERATE || '') as number) : undefined,
  SENTRY_REPLAYSSESSIONSAMPLERATE: env.SENTRY_REPLAYSSESSIONSAMPLERATE
    ? (+(env.SENTRY_REPLAYSSESSIONSAMPLERATE || '') as number)
    : undefined,
  SENTRY_REPLAYSONERRORSAMPLERATE: env.SENTRY_REPLAYSONERRORSAMPLERATE
    ? (+(env.SENTRY_REPLAYSONERRORSAMPLERATE || '') as number)
    : undefined,
  FEATURE_FLAG_CLIENT_KEY: env.FEATURE_FLAG_CLIENT_KEY,
  FEATURE_FLAG_HOST: env.FEATURE_FLAG_HOST,
  FEATURE_FLAG_REFRESH_INTERVAL_SECONDS: env.FEATURE_FLAG_REFRESH_INTERVAL_SECONDS
    ? (+(env.FEATURE_FLAG_REFRESH_INTERVAL_SECONDS || '') as number)
    : 60,
  HUB_BASE_URL: env.HUB_BASE_URL,
  HUB_FORCE_LONG_POLLING: env.HUB_FORCE_LONG_POLLING,
  OPTIMIZATION_ABSOLUTE_DISTANCE_TRESHOLD_METERS: env.OPTIMIZATION_ABSOLUTE_DISTANCE_TRESHOLD_METERS,
  OPTIMIZATION_ABSOLUTE_DISTANCE_TRESHOLD_PERCENTAGE: env.OPTIMIZATION_ABSOLUTE_DISTANCE_TRESHOLD_PERCENTAGE,
};
