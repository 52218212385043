import { Box, CircularProgress, Typography } from '@mui/material';
import { GridFooterContainer, GridFooter as MuiGridFooter } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { formatDateLong, formatTime } from '../../helpers/DateTimeHelper';

type GridFooterProps = {
  isRefetching: boolean;
  lastUpdatedAt: Date;
};

export const GridFooter = ({ isRefetching, lastUpdatedAt }: GridFooterProps) => {
  const { t, i18n } = useTranslation('common');
  const date = formatDateLong(lastUpdatedAt, i18n.resolvedLanguage);
  const time = formatTime(lastUpdatedAt);
  return (
    <GridFooterContainer>
      <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center'>
        <Box sx={{ px: 1 }}>{isRefetching ? <CircularProgress color='primary' size={15} /> : <Box sx={{ m: 0.92 }} />}</Box>
        <Typography variant='caption'>{t('gridFooter.lastUpdatedAt', { date, time })}</Typography>
      </Box>
      <MuiGridFooter sx={{ border: 'none' /* To delete double border. */ }} />
    </GridFooterContainer>
  );
};
