import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CancelEditWarningModal } from '../components/modal/CancelEditWarningModal';
import { useBlocker } from 'react-router-dom';

type Callbacks = { onBlock?: () => void; onStay?: () => void };
type SubscribeType = (key: string, state: () => boolean, callbacks?: Callbacks) => void;
type UnsubscribeType = (key: string) => void;
type ContextType = { subscribe: SubscribeType; unsubscribe: UnsubscribeType };
type Props = {
  handleSave: () => Promise<boolean>;
} & PropsWithChildren;

const BlockerContext = createContext<ContextType>({ subscribe: () => {}, unsubscribe: () => {} } as ContextType);

export const useBlockerContext = () => {
  return useContext(BlockerContext);
};

export function BlockerProvider({ children, handleSave }: Props) {
  const subscriptions = useRef<Record<string, { state: () => boolean; onBlock?: () => void; onStay?: () => void }>>({});
  const [isSaving, setIsSaving] = useState(false);
  const [cancelEditWarningModalOpened, setCancelEditWarningModalOpened] = useState(false);

  const subscribe = useCallback((key: string, state: () => boolean, { onBlock, onStay }: Callbacks = {}) => {
    subscriptions.current[key] = { state, onBlock, onStay };
  }, []);
  const unsubscribe = useCallback((key: string) => {
    delete subscriptions.current[key];
  }, []);
  const blocker = useBlocker(() => {
    const activeSubscription = Object.values(subscriptions.current).find(({ state }) => state());
    if (activeSubscription) {
      setCancelEditWarningModalOpened(true);
      activeSubscription.onBlock?.();
    }
    return !!activeSubscription;
  });

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      const activeSubscription = Object.values(subscriptions.current).find(({ state }) => state());
      if (activeSubscription) {
        event.preventDefault();
        return (event.returnValue = '');
      }
      return null;
    };
    addEventListener('beforeunload', handler);

    return () => removeEventListener('beforeunload', handler);
  }, []);

  const handleClose = useCallback(() => {
    Object.keys(subscriptions.current).forEach((key) => subscriptions.current[key].onStay?.());
    setCancelEditWarningModalOpened(false);
    blocker.reset?.();
  }, [blocker]);

  const handleQuit = useCallback(() => {
    setCancelEditWarningModalOpened(false);
    blocker.proceed?.();
  }, [blocker]);

  const handleSaveAndQuit = useCallback(async () => {
    let isSuccess = false;
    try {
      setIsSaving(true);
      isSuccess = await handleSave();
    } finally {
      setIsSaving(false);
    }

    setCancelEditWarningModalOpened(false);
    if (isSuccess) blocker.proceed?.();
  }, [handleSave, blocker]);

  const value = useMemo(() => ({ subscribe, unsubscribe }), [subscribe, unsubscribe]);

  return (
    <BlockerContext.Provider value={value}>
      {children}
      <CancelEditWarningModal
        open={cancelEditWarningModalOpened}
        onClose={handleClose}
        onSave={handleSaveAndQuit}
        onQuit={handleQuit}
        isSaving={isSaving}
      />
    </BlockerContext.Provider>
  );
}
