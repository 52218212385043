const information = {
  title: 'Informations',
  date: 'Date',
  client: 'Nom du client',
  siteName: 'Nom du chantier',
  siteAddress: 'Adresse du chantier',
  workType: 'Nature du travail',
  otNumber: 'No OT',
  operator: 'Opérateur',
  unitNumber: 'No Unité',
  gtNumber: 'No GT',
};

const serviceHours = {
  addButtonLabel: 'Ajouter',
  fromBiggerThanToError: "L'heure de début doit être antérieure à l'heure de fin",
  title: 'Heures facturables',
  overlapTimeRangeError: 'Plages horaires en chevauchement',
  header: {
    from: 'De',
    to: 'À',
    activity: 'Activité',
    single: 'Simp.',
    single_tip: 'Simple',
    singleAndAHalf: 'Demi',
    singleAndAHalf_tip: 'Demi',
    double: 'Dou.',
    double_tip: 'Double',
    quantity: 'Qté',
    quantity_tip: 'Quantité',
    dispatch: 'Rép.',
    dispatch_tip: 'Répartition',
  },
  options: {
    lunchBreak: 'Repas',
    serviceHour: 'Fact.',
  },
  craneService: 'Service de grue',
  noData: 'Aucune heure de service de grue',
};

const timeRangeSection = {
  from: 'De',
  to: 'À',
  total: 'Total',
  comment: 'Commentaire',
};

const detailNavbar = {
  informations: 'Informations',
  validation: 'Validation',
};

const exportButton = {
  editingActive: "Impossible d'exporter pendant la modification",
  title: 'Exporter',
  unfinishedContractAgreement: "Impossible d'exporter un Contrat de service non terminé",
  exportOriginal: "Exporter l'original",
  exportLatestVersion: 'Exporter la version à jour',
  exportErrorPdf: "Erreur lors de l'exportation du PDF",
};

const detail = {
  exportButton,
  title: 'Contrat de service',
  noData: 'Aucun contrat de service à afficher.',
  abbreviation: 'CS',
  edit: 'Modifier',
  cancel: 'Annuler',
  save: 'Terminer',
  saveSuccessMessage: 'Les modifications au contrat de service ont été enregistrées avec succès',
  numberAlreadySet: 'Numéro déjà assigné',
  numberInvalid: 'Numéro invalide',
  numberLabel: 'Numéro',
  generateNumber: 'Générer',
  numberDisabled: 'OT en répartition',
};

const mechanicalFailures = {
  fromBiggerThanToError: "L'heure de début doit être antérieure à l'heure de fin",
  title: 'Bris mécaniques',
  overlapTimeRangeError: 'Plages horaires en chevauchement',
  comment: 'Commentaire',
  noData: 'Aucun bris mécanique',
};

const lunchBreaks = {
  fromBiggerThanToError: "L'heure de début doit être antérieure à l'heure de fin",
  title: 'Heure de repas',
  overlapTimeRangeError: 'Plages horaires en chevauchement',
};

const preparations = {
  numberOfHours_one: '{{count}} heure',
  numberOfHours_other: '{{count}} heures',
  preparation: 'Préparation au travail',
  preparations: 'Préparation au travail',
  preparation_one: 'Préparation: {{count}} heure',
  preparation_other: 'Préparation: {{count}} heures',
};

const additionalFees = {
  select: 'Sélectionner',
  noData: 'Aucuns frais additionnels',
  title: 'Frais additionnels',
  preparations,
  meals: 'Repas',
  taxis: 'Frais taxis',
  parking: 'Frais stationnement',
  travellingExpenses: 'Frais de déplacement',
  carAllowances: 'Utilisation du véhicule',
  pension: 'Pension totale: {{days}} jours x {{people}} personnes',
  pensions: 'Pension totale: {{days}} jours x {{people}} personnes',
  options: {
    meals: 'Repas',
    taxis: 'Frais taxis',
    parking: 'Frais stationnement',
    travellingExpenses: 'Frais de déplacement',
    carAllowances: 'Utilisation du véhicule',
    pensions: 'Pension',
    preparations: 'Préparation au travail',
  },
  edit: {
    pensions: {
      label: 'Pension',
      type: 'Type',
      daysPerPerson: '(jours par personne)',
      numberOfPersons: 'Nombre de personnes',
      unitOfTimeCount: 'Nombre de jours',
      options: {
        day: 'Jour',
        twoWeeks: '2 semaines +',
      },
    },
    travellingExpenses: {
      to: 'à',
      kmTravelled: 'Km parcourus',
    },
  },
  travelFee: {
    label: 'Frais de déplacement',
    editLabel: 'Frais de déplacement',
    none: 'Pas de déplacement',
    range49To72: '49 à 72 km',
    range73To88: '73 à 88 km',
    range89To119: '89 à 119 km',
    range120AndMore: '120 km +',
    siteTransfer: 'Transfert chantier',
  },
  hour_one: '{{count}} heure',
  hour_other: '{{count}} heures',
  header: {
    fee: 'Frais',
    distribution: 'Rép.',
    distribution_tip: 'Répartition',
  },
};

const additionalTimes = {
  title: 'Temps additionnels',
  manTransport: "Transport d'hommes: {{hours}} heures x {{people}} personnes",
  manTime: "Temps d'hommes: {{hours}} heures x {{people}} personnes",
  hours_one: '{{count}} heure',
  hours_other: '{{count}} heures',
  edit: {
    addAdditionalTimesLabel: 'Ajouter un temps additionnel',
    noBreakTimes: 'No Break',
    noLunchTimes: 'No Lunch',
    manTransport: {
      label: "Transport d'hommes",
      numberOfPersons: 'Nombre de personnes',
      unitOfTimeCount: "Nombre d'heures",
    },
    manTime: {
      label: "Temps d'hommes",
      numberOfPersons: 'Nombre de personnes',
      unitOfTimeCount: "Nombre d'heures",
    },
  },
  overlapTimeRangeError: 'Plages horaires en chevauchement',
  fromBiggerThanToError: 'La date de début doit être antérieure à la date de fin',
  noTimeRangeError: 'Plage horaire manquante',
};

const comments = {
  title: 'Commentaires',
};

const accessories = {
  title: 'Accessoires',
  noData: 'Aucun accessoire',
  headers: {
    type: "Type d'accessoire",
    accessory: 'Accessoire',
    unit: 'Unité',
    quantity: 'Qté',
    used: 'Utilisé',
  },
  billingCodes: {
    hour: 'Heure',
    day: 'Jour',
    week: 'Semaine',
    month: 'Mois',
  },
  quantityMatchError: 'La quantité d’heures ne correspond pas au total facturable du Contrat de service',
};

const overview = {
  client: 'Client',
  date: 'Date',
  representative: 'Chargé de projet',
};

const crane = {
  unitNo: 'No Unité',
  operators: `Main-d'oeuvre`,
};

const headers = {
  contractAgreementNumber: 'Numéro du CS',
  ordreDeTravailNumber: "Numéro de l'OT",
  comment: 'Commentaires',
  salesBranch: 'Succursale des Ventes',
  dispatchBranchNumber: 'Succursale de répartition',
  ...crane,
  client: overview.client,
  contractAgreementStatus: 'Statut du CS',
  date: overview.date,
  workType: 'Nature du Travail',
  workDescription: 'Description du Travail',
  representativeName: overview.representative,
  site: 'Chantier',
};

const list = {
  title: 'Contrats de services',
  headers,
};

const signature = {
  title: 'Signature',
  noData: 'Aucune signature',
};

const attachmentsSection = {
  title: 'Pièces jointes',
  notUploaded: 'Cette pièce jointe n’a toujours pas été téléversée',
  preview: 'Voir la pièce jointe',
  download: 'Télécharger la pièce jointe',
};

const buttonToggle = {
  yes: 'Oui',
  no: 'Non',
};

const numberUpdateWarningModal = {
  content_generate: 'Attention, cette action est irréversible. Un numéro sera automatiquement assigné au contrat de service.',
  content_set:
    'Attention, cette action est irréversible. Le numéro que vous avez entré sera assigné au contrat de service actuel si valide',
};

const statusUpdateWarningModal = {
  content: 'Attention, cette action est irréversible. Le statut du contrat de service sera mis à jour.',
};

const root = {
  noOperatorName: 'Nom indisponible',
  addButtonLabel: 'Ajouter',
};

export const contractAgreement = {
  ...root,
  detail,
  detailNavbar,
  information,
  serviceHours,
  timeRangeSection,
  mechanicalFailures,
  additionalFees,
  additionalTimes,
  comments,
  accessories,
  list,
  lunchBreaks,
  signature,
  attachmentsSection,
  buttonToggle,
  numberUpdateWarningModal,
  statusUpdateWarningModal,
};
