import { Typography } from '@mui/material';

type Props = {
  value: string;
};

export const HeaderCell = ({ value }: Props): JSX.Element => {
  return (
    <Typography variant='body2' sx={{ fontWeight: 500 }}>
      {value}
    </Typography>
  );
};
