import { useNavigate, useParams } from 'react-router-dom';
import { useFetchWorkKinds } from '../hooks/useFetchWorkKinds';

import { WorkKindRuleListItemRecord } from '../models/workKindsModel';

import { SxProps, darken } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from '../../../common/components/ConfirmationModal';
import { defaultPrimaryColor, white } from '../../../common/constants/colors';
import { AutomationRuleRecord } from '../models/automatisationModel';
import AutomationRuleDetailHeader, { useHeaderPageContext } from './AutomationRuleDetailHeader';
import { AutomationRuleForm } from './AutomationRuleForm';
import { useDeleteAutomationRule } from './hooks/useDeleteAutomationRule';
import { useFetchAutomationRule } from './hooks/useFetchAutomationRule';
import { AutomationRuleParams } from './params';

export const EditAutomationRulePage = (): JSX.Element => {
  const { id } = useParams<AutomationRuleParams>();
  const { t } = useTranslation('workKinds');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { data: automationRule, isLoading: isLoadingRule, error } = useFetchAutomationRule(id!);
  const { data: workKinds, isLoading: isLoadingWorkKinds } = useFetchWorkKinds();
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const { deleteAutomationRule, isLoading: isDeleting } = useDeleteAutomationRule();
  const workKindsData = workKinds?.items ?? [];
  const isLoading = isLoadingRule || isLoadingWorkKinds || isDeleting;

  const handleDeleteClick = useCallback(() => {
    setConfirmationModalOpen(true);
  }, []);

  const handleDelete = useCallback(async () => {
    if (!id) return;
    try {
      await deleteAutomationRule(id);
      queryClient.invalidateQueries({ queryKey: ['fetchAutomations'] });
      enqueueSnackbar(t('automationRuleForm.deleteSuccess'), { variant: 'success' });
      navigate(-1);
    } catch (e) {
      enqueueSnackbar(t('automationRuleForm.deleteError'), { variant: 'error' });
      console.error(e);
    } finally {
      setConfirmationModalOpen(false);
    }
  }, [deleteAutomationRule, id, navigate, queryClient, enqueueSnackbar, t]);

  return (
    <AutomationRuleDetailHeader isLoading={isLoading} error={error} onDelete={handleDeleteClick}>
      <>
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          title={t('automationRuleForm.confirmDeleteTitle')}
          message={t('automationRuleForm.confirmDeleteMessage')}
          onConfirm={handleDelete}
          confirmStyle={styles.confirmDeleteButton}
          onCancel={() => setConfirmationModalOpen(false)}
        />
        <WrapAutomationFormWithHeaderContext automationRule={automationRule} isLoading={isLoading} workKinds={workKindsData} />
      </>
    </AutomationRuleDetailHeader>
  );
};

function WrapAutomationFormWithHeaderContext({
  automationRule,
  isLoading,
  workKinds,
}: {
  automationRule: AutomationRuleRecord | null;
  isLoading: boolean;
  workKinds: WorkKindRuleListItemRecord[];
}) {
  const { actionsRef } = useHeaderPageContext();
  return (
    <AutomationRuleForm mode='edit' automationRule={automationRule} actionsRef={actionsRef} isLoading={isLoading} workKinds={workKinds} />
  );
}

const styles: { [key: string]: SxProps } = {
  confirmDeleteButton: {
    color: white,
    backgroundColor: defaultPrimaryColor,
    textTransform: 'uppercase',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: darken(defaultPrimaryColor, 0.275),
    },
  },
};
