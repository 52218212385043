import { useFlag } from '@unleash/proxy-client-react';
import { createContext, PropsWithChildren, useContext } from 'react';

const MaintenanceContext = createContext<boolean>(false);

export function Maintenance({ children }: PropsWithChildren) {
  const genericState = useFlag('web_maintenance');
  const isUserAffected = useFlag('web_maintenance_bypass');
  const enabled = genericState && !isUserAffected;
  return <MaintenanceContext.Provider value={enabled}>{children}</MaintenanceContext.Provider>;
}

export const useMaintenance = () => {
  return useContext(MaintenanceContext);
};
