import { AbilityBuilder, AbilityClass, AbilityTuple, MatchConditions, PureAbility } from '@casl/ability';
import { AxiosError } from 'axios';
import { defineAcknowledgmentsAbilities } from '../acknowledgements/authorization/abilities';
import { EmployeeAccessRecord } from '../common/models/models';
import { defineConfigurationAbilities } from '../configuration/authorization/abilities';
import { defineContractAgreementAbilities } from '../contractAgreement/authorization/abilities';
import { defineDispatchAbilities } from '../dispatch/authorization/abilities';
import { defineOrdreDeTravailAbilities } from '../ordreDeTravail/authorization/abilities';
import { defineTimesheetAbilities } from '../timesheet/authorization/abilities';
import { defineValidationAbilities } from '../validationNext/authorization/abilities';
import { defineWorkKindRulesAbilities } from '../workKinds/authorization/abilities';
import { defineAppAbilities } from './abilities';

export type AppAbility = PureAbility<AbilityTuple, MatchConditions>;
const AppAbility = PureAbility as AbilityClass<AppAbility>;

export default function defineAbilitiesFor(user: EmployeeAccessRecord | undefined, error: AxiosError | null | undefined): AppAbility {
  const abilityBuilder = new AbilityBuilder(AppAbility);
  const lambdaMatcher = (matchConditions: MatchConditions) => matchConditions;
  if (!user) return abilityBuilder.build();
  const userBranchIds = user.editableBranches?.map((branch) => branch.id);
  const userRoles = user.roles;

  defineAppAbilities(abilityBuilder, userRoles, error);
  defineConfigurationAbilities(abilityBuilder, userRoles);
  defineTimesheetAbilities(abilityBuilder, userRoles);
  defineContractAgreementAbilities(abilityBuilder, userRoles, userBranchIds);
  defineDispatchAbilities(abilityBuilder, userRoles, userBranchIds);
  defineValidationAbilities(abilityBuilder, userRoles);
  defineAcknowledgmentsAbilities(abilityBuilder, userRoles);
  defineOrdreDeTravailAbilities(abilityBuilder, userRoles);
  defineWorkKindRulesAbilities(abilityBuilder, userRoles);

  return abilityBuilder.build({ conditionsMatcher: lambdaMatcher });
}
