import { GridPaginationModel } from '@mui/x-data-grid-premium';
import { isFunction } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';

type UseTablePaginationResult = {
  paginationModel: GridPaginationModel;
  handlePaginationModelChange(input: UpdateModelFunction | GridPaginationModel): void;
};

type UpdateModelFunction = (prevModel: GridPaginationModel) => GridPaginationModel;

export const useTablePagination = (key: string, initialValue: number): UseTablePaginationResult => {
  const [storedPageSizeValue, setPageSizeValue] = useLocalStorage(key, initialValue);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageIndex, setPageIndex] = useState<number>(parseInt(searchParams.get('page') ?? '1', 10) - 1);

  const updatePageSearchParam = useCallback(
    (newValue: number) => {
      setSearchParams(
        (prevSearchParams) => {
          prevSearchParams.set('page', (newValue + 1).toString());
          return prevSearchParams;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const handlePaginationModelChangeWithModelInput = useCallback(
    ({ pageSize: newPageSize, page: newPage }: GridPaginationModel) => {
      setPageSizeValue(newPageSize);
      setPageIndex(newPage);
      updatePageSearchParam(newPage);
    },
    [setPageSizeValue, updatePageSearchParam],
  );

  const handlePaginationModelChangeWithMethodInput = useCallback(
    (update: UpdateModelFunction) => {
      setPageSizeValue((prevPageSize) => {
        let newPageSize: number = prevPageSize;
        setPageIndex((prevPageIndex) => {
          const newModel = update({ pageSize: prevPageSize, page: prevPageIndex });
          newPageSize = newModel.pageSize;
          updatePageSearchParam(newModel.page);
          return newModel.page;
        });
        return newPageSize;
      });
    },
    [setPageSizeValue, updatePageSearchParam],
  );

  const handlePaginationModelChange = useCallback(
    (input: UpdateModelFunction | GridPaginationModel) => {
      if (isFunction(input)) {
        handlePaginationModelChangeWithMethodInput(input);
      } else {
        handlePaginationModelChangeWithModelInput(input);
      }
    },
    [handlePaginationModelChangeWithModelInput, handlePaginationModelChangeWithMethodInput],
  );

  return useMemo<UseTablePaginationResult>(
    () => ({
      paginationModel: {
        page: pageIndex,
        pageSize: storedPageSizeValue,
      },
      handlePaginationModelChange,
    }),
    [handlePaginationModelChange, pageIndex, storedPageSizeValue],
  );
};
