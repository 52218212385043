import {
  ContractAgreementStatus,
  CraneOperatorKind,
  DailyTimeSheetStatus,
  JobCode,
  OrdreDeTravailStatus,
  OrdreDeTravailValidationStatus,
  ServiceCallKind,
  TimeSheetEntryStatus,
  WorkKind,
  WorkType,
} from '../../generated/schemas';
import { EntityTypeErrorEnum } from '../components/page/ErrorMessageHelpers';

const autocomplete = {
  clearText: 'Retirer',
  noOptions: 'Aucune autre option',
  select: 'Sélectionner',
};
const button = {
  add: 'Ajouter',
  cancel: 'Annuler',
  close: 'Fermer',
  confirm: 'Confirmer',
  continue: 'Continuer',
  return: 'Retour',
  returnToPreviousPage: 'Retour à la page précédente',
  save: 'Enregistrer',
  yes: 'Oui',
  no: 'Non',
  edit: 'Modifier',
  replace: 'Remplacer',
  refresh: 'Rafraichir',
  create: 'Créer',
  submit: 'Soumettre',
  viewMore: 'Voir plus',
  viewLess: 'Voir moins',
  done: 'Terminer',
  delete: 'Supprimer',
};

const saveResult = {
  save_validation: 'Les données sont invalides, corriger les erreurs pour enregistrer.',
  save_success: 'Enregistré avec succès.',
  save_error: 'Une erreur est survenue lors de la sauvegarde. Veuillez réessayer.',
  save_skip: 'Aucune modification détectée.',
  save: 'Enregistré',
  submit_error: 'Une erreur est survenue lors de la soumission. Veuillez réessayer.',
};

const attachmentResult = {
  no_uri: 'Une pièce jointe est introuvable.',
  upload_error_one: 'Une pièce jointe a été refusée lors du téléversement.',
  upload_error_other: '{{count}} pièces jointes ont été refusées lors du téléversement.',
};

const navigation = {
  dispatch: 'Répartition',
  acknowledgements: 'Suivis de lecture',
  contractAgreements: 'Contrats de service',
  timesheets: 'Cartes de temps',
  validations: 'Validations',
  ordresDeTravail: 'Ordres de travail',
  configuration: 'Configuration',
  workKinds: 'Genres de travail',
};

const serverConnectionErrorPanel = {
  title: 'Le serveur ne répond pas',
  content: 'Veuillez vérifier votre connexion Internet ou ',
  contactAdmin: 'Contacter votre administrateur.',
  refreshButtonLabel: 'Réessayer',
};

const commonTableToolbar = {
  resetSortAndFiltersButton: 'Réinitialiser tris/filtres',
  resetColumnsButton: 'Réinitialiser colonnes',
};

const gridFooter = {
  lastUpdatedAt: 'Données rafraîchies le {{date}} à {{time}}',
};

const savingErrorModal = {
  title: 'La sauvegarde a échoué!',
  message: 'Une erreur est survenue lors de la sauvegarde. Veuillez réessayer.',
};

const cancelConfirmModal = {
  title: 'Avertissement',
  cancel: 'Annuler',
  confirm: 'Confirmer',
};

const timeRangePicker = {
  from: 'De',
  to: 'À',
  select: 'Sélectionner',
};

const weekPicker = {
  reset: 'Retirer les dates',
  select: 'Sélectionner une semaine',
};

const somethingWentWrong = {
  somethingWentWrong: "Quelque chose s'est mal passé",
};

const cancelEditWarningModal = {
  title: 'Modification(s) non enregistrée(s)',
  content: 'Si vous naviguez à l’extérieur de la page, vos modifications seront perdues.',
  quit: 'Quitter',
  stayOnEdit: 'Rester sur la page',
  saveAndQuit: 'Enregistrer et quitter',
};

const workTypeWithCode = {
  [WorkType.Industrial]: '29 - Industriel (Baie James isolé)',
  [WorkType.RegularLabor]: '30 - Travail régulier',
  [WorkType.IndustrialSite]: '31 - Chantier industriel',
  [WorkType.HeavyIndustries]: '32 - Industries lourdes',
  [WorkType.Pipeline]: '33 - Pipeline',
  [WorkType.PowerLineErection]: '34 - Ligne électrique - Érection',
  [WorkType.GeneralElectricPostLine]: '35 - Ligne poste électrique - Général',
  [WorkType.CivilEngineeringAndRoads]: '36 - Génie civil et voirie',
  [WorkType.WindTurbine]: '37 - Éoliennes',
  [WorkType.Barracks]: '38 - Baraquement',
  [WorkType.JamesBayAndIsolatedWorksite]: '39 - Baie-James & Chantiers isolés',
  [WorkType.OutsideProvinceQuebec]: '99 - Extérieur province Québec',
};

const workType = {
  [WorkType.Industrial]: 'Industriel (Baie James isolé)',
  [WorkType.RegularLabor]: 'Travail régulier',
  [WorkType.IndustrialSite]: 'Chantier industriel',
  [WorkType.HeavyIndustries]: 'Industries lourdes',
  [WorkType.Pipeline]: 'Pipeline',
  [WorkType.PowerLineErection]: 'Ligne électrique - Érection',
  [WorkType.GeneralElectricPostLine]: 'Ligne poste électrique - Général',
  [WorkType.CivilEngineeringAndRoads]: 'Génie civil et voirie',
  [WorkType.WindTurbine]: 'Éoliennes',
  [WorkType.Barracks]: 'Baraquement',
  [WorkType.JamesBayAndIsolatedWorksite]: 'Baie-James & Chantiers isolés',
  [WorkType.OutsideProvinceQuebec]: 'Extérieur province Québec',
};

const serviceCallKind = {
  [ServiceCallKind.AccessoriesRental]: 'Location d’accessoires',
  [ServiceCallKind.Bare]: 'Grue non-opérée',
  [ServiceCallKind.BareWithOperators]: 'Grue non-opérée avec opérateurs',
  [ServiceCallKind.LaborRental]: 'Location de main-d’œuvre',
  [ServiceCallKind.LiftingPlan]: 'Plan de levage',
  [ServiceCallKind.LiftingTest]: 'Test de levage',
  [ServiceCallKind.OperatedHourly]: "Grue opérée (à l'heure)",
  [ServiceCallKind.OperatedMonthly]: 'Grue opérée (mensuelle)',
  [ServiceCallKind.RollingEquipment]: 'Matériel roulant',
  [ServiceCallKind.LaborRentalOnOperated]: 'Location de main-d’œuvre (opérée)',
  [ServiceCallKind.LaborRentalOnBare]: 'Location de main-d’œuvre (non-opérée)',
  [ServiceCallKind.Storage]: 'Entreposage',
};

const workKind = {
  [WorkKind.AssemblyAndDisassembly]: '11 - Montage et démontage',
  [WorkKind.BillableManTransport]: "18 - Transport d'hommes (Facturable)",
  [WorkKind.BillableBreakageRepair]: '13 - Réparation facturable',
  [WorkKind.BreakageRepair]: '12 - Bris (En réparation)',
  [WorkKind.BreakageWaiting]: '10 - Bris (En attente)',
  [WorkKind.CarKmAllowance]: '54 - Utilisation du véhicule',
  [WorkKind.CNesst]: '21 - C.N.E.S.S.T',
  [WorkKind.CraneAssistant]: '02 - Aide-grutier',
  [WorkKind.CraneAssistantNotOperatedRental]: '25 - Aide-grutier (non opérée)',
  [WorkKind.CraneOperator]: '01 - Grutier',
  [WorkKind.CraneOperatorsNonOperatedRental]: '25 - Grutier (non opérée)',
  [WorkKind.CraneTransport]: '07 - Transport de grue',
  [WorkKind.CraneTransportAssistant]: '37 - Transport de grue (assistant)',
  [WorkKind.CraneYardPreparation]: '06 - Travail à la cour',
  [WorkKind.Maintenance]: '05 - Entretien',
  [WorkKind.Meal]: '51 - Repas',
  [WorkKind.Mechanic]: '14 - Mécanicien (Cour ou garage)',
  [WorkKind.NonBillableAssemblyAndDisassembly]: '31 - Montage et démontage (Non facturable)',
  [WorkKind.NonBillableManTransport]: "08 - Transport d'hommes (Non facturable)",
  [WorkKind.NonBillableTwoManTransportDriver]: '47 - Conducteur (Transport à deux, non facturable)',
  [WorkKind.PaidHoursNotOperated]: '15 - Heures payées non opérées',
  [WorkKind.Parking]: '59 - Stationnement',
  [WorkKind.Representation]: '24 - Représentation',
  [WorkKind.RestOrMealPeriod]: '35 - Période de repos/repas',
  [WorkKind.RoomAndBoardDay]: '52 - Chambre et pension (Jour)',
  [WorkKind.RoomAndBoardTwoPlusWeeks]: '53 - Chambre et pension (2+ semaines)',
  [WorkKind.SiteManTransfer]: '28 - Transfert homme chantier',
  [WorkKind.SpecialLeaves]: '22 - Congés spéciaux',
  [WorkKind.TaxiBusOrTolls]: '62 - Taxi, autobus, péage',
  [WorkKind.TowerCrane]: '04 - Grue à tour',
  [WorkKind.Training]: '20 - Formation',
  [WorkKind.TravelExpenses120AndMoreKm]: '58 - Frais déplacement 120 km+',
  [WorkKind.TravelExpenses49To72Km]: '55 - Frais déplacement 49 à 72 km',
  [WorkKind.TravelExpenses73To88Km]: '56 - Frais déplacement 73 à 88 km',
  [WorkKind.TravelExpenses89To119Km]: '57 - Frais déplacement 89 à 119 km',
  [WorkKind.TravelExpensesSiteTransfer]: '58 - Frais déplacement Transfert chantier',
  [WorkKind.Trucker]: '03 - Camionneur',
  [WorkKind.TwoManTransportDriver]: '17 - Conducteur (Transport à deux)',
  [WorkKind.Waiting]: '09 - Attente',
  [WorkKind.WorkPreparation]: '16 - Préparation au travail',
  [WorkKind.RoomAndBoardPipeline]: '50 - Chambre et pension (Pipeline)',

  // THE FOLLOWING BLOCK CONTAINS VALUES THAT ARE DEPRECATED AND ONLY USED FOR ALREADY EXISTING VALUES
  [WorkKind.Administration]: '0 - Administration',
  [WorkKind.Escort]: '19 - Escorte',
  [WorkKind.Holiday]: '42 - Férié',
  publicHoliday: '42 - Férié',
  [WorkKind.SeverancePay]: '43 - Indemnité de départ',
  [WorkKind.Sickness]: '41 - Maladie',
  [WorkKind.TransferManToLoad]: '38 - Transfer homme à charger',
  [WorkKind.TravelExpenses61To90Km]: '60 - Frais déplacement 61 à 90 Km',
  [WorkKind.TravelExpenses91To119Km]: '61 - Frais déplacement 91 à 119 Km',
  [WorkKind.Vacation]: '40 - Vacances',

  // THE FOLLOWING BLOCK CONTAINS VALUES THAT HAVE BEEN RENAMED BUT TARGET THE CORRECT TRANSLATIONS
  [WorkKind.AutoKmAllowance]: '54 - Allocation auto km',
  [WorkKind.CraneOperatorMenToLoad]: "18 - Transport d'hommes (Facturable)",
  [WorkKind.CraneOperatorTransportToLoad]: '17 - Conducteur (Transport à deux)',
  [WorkKind.Csst]: '21 - C.N.E.S.S.T.',
  [WorkKind.MenTransport]: "08 - Transport d'hommes (Non facturable)",
  operator: '01 - Grutier',
  [WorkKind.PresentationAtWork]: '16 - Préparation au travail',
  [WorkKind.Repair]: '12 - Bris (En réparation)',
  [WorkKind.RoadBreakageWaiting]: '10 - Bris (En attente)',
  [WorkKind.RoomAndBoardWeek]: '53 - Chambre et pension (2+ semaines)',
  [WorkKind.TaxiBusParking]: '59 - Stationnement',
};

const jobCode = {
  [JobCode.Administration]: 'Administration',
  [JobCode.Charpentier]: 'Charpentier',
  [JobCode.Chaudronnier]: 'Chaudronnier',
  [JobCode.Electricien]: 'Électricien',
  [JobCode.Grutier]: 'Grutier',
  [JobCode.GrutierClasseA]: 'Grutier classe A',
  [JobCode.GrutierClasseADeuxiemeHomme]: 'Grutier classe A deuxieme homme',
  [JobCode.GrutierClasseB]: 'Grutier classe B',
  [JobCode.MecanicienAscenseur]: 'Mécanicien ascenseur',
  [JobCode.MecanicienChantier]: 'Mécanicien chantier',
  [JobCode.MecanicienMachineriesLourdes]: 'Mécanicien machineries lourdes',
  [JobCode.MonteurAcierStructure]: 'Monteur acier structure',
  [JobCode.OperateurEquipementLourd]: 'Opérateur équipement lourd',
  [JobCode.SoudeurMachinerieLourde]: 'Soudeur machinerie lourde',
};

const ordreDeTravailStatus = {
  [OrdreDeTravailStatus.Upcoming]: 'À venir',
  [OrdreDeTravailStatus.Ongoing]: 'En cours',
  [OrdreDeTravailStatus.Cancelled]: 'Annulé',
  [OrdreDeTravailStatus.Modified]: 'Modifié',
  [OrdreDeTravailStatus.Finished]: 'Terminé',
  [OrdreDeTravailStatus.Dispatching]: 'Répartition',
  [OrdreDeTravailStatus.External]: 'Externe',
  [OrdreDeTravailStatus.Internal]: 'Interne',
};

const ordreDeTravailValidationsStatuses = {
  [OrdreDeTravailValidationStatus.DocumentsReceived]: 'Documents reçus',
  [OrdreDeTravailValidationStatus.Open]: 'Ouvert',
  [OrdreDeTravailValidationStatus.Submitted]: 'Soumis',
  [OrdreDeTravailValidationStatus.Upcoming]: 'À venir',
  [OrdreDeTravailValidationStatus.Entering]: 'En saisie',
  [OrdreDeTravailValidationStatus.Validated]: 'Validé',
  [OrdreDeTravailValidationStatus.Cancelled]: 'Annulé',
  [OrdreDeTravailValidationStatus.External]: 'Externe',
  [OrdreDeTravailValidationStatus.Internal]: 'Interne',
};

const timeSheetStatus = {
  [DailyTimeSheetStatus.Completed]: 'Terminée',
  [DailyTimeSheetStatus.Ongoing]: 'En cours',
  [DailyTimeSheetStatus.NotStarted]: 'Non débutée',
};

const timeSheetEntryStatus = {
  [TimeSheetEntryStatus.Completed]: 'Terminée',
  [TimeSheetEntryStatus.Ongoing]: 'En cours',
  [TimeSheetEntryStatus.Validated]: 'Validée',
  [TimeSheetEntryStatus.Submitted]: 'Soumise',
};

const contractAgreementStatus = {
  [ContractAgreementStatus.Created]: 'À venir',
  [ContractAgreementStatus.Ongoing]: 'En cours',
  [ContractAgreementStatus.Finished]: 'Terminé',
  editing: 'Modification en cours',
  unknown: 'Inconnu',
};

const contractAgreementSubmitted = {
  submitted: 'Soumis',
  validated: 'Validé',
};

const replacedOperator = {
  replaced: 'Remplacé',
};

const insufficientPermissions = {
  title: "Vous ne possédez pas le niveau d'accès pour voir le contenu de cette page",
  contactAdmin: 'Contactez votre administrateur',
};

const travellingExpensesButtonGroup = {
  kmRange: '{{from}} à {{to}} km',
  numberOfHours_one: '{{count}} heure',
  numberOfHours_other: '{{count}} heures',
  errorLabel: 'La valeur doit être entre {{min}} et {{max}}',
};

const timeRangeTable = {
  from: 'De',
  to: 'À',
  poste: 'Poste',
  type: 'Nature',
  quantity: 'Qté',
  workOrderNumber: 'Equi./WO',
  gtNumber: '#GT',
  branch: 'Suc.',
  simple: 'Simple',
  singleAndAHalf: 'Demi',
  double: 'Double',
  automaticPrime: 'P. Aut',
  variablePrime: 'P. Var',
  automaticPrimeLong: 'Prime Auto',
  variablePrimeLong: 'Prime Vari',
  rate: 'Taux',
  linkedTo: 'Lié À',
  footer: {
    total: 'Total',
    weeklyTotal: 'Total hebdo.',
  },
};

const splitTimeRangeOrPremiumModal = {
  title: 'Séparer une plage horaire',
  addNewTimeRange: 'Ajouter une plage',
  warningTotalHoursQuantity: `Attention, la quantité d'heures saisie est différente de la quantité d'heures de départ.`,
  errors: {
    emptyFields: `Des plages de temps sont vides. Merci de corriger.`,
    overlappingFields: `Certaines plages de temps se chevauchent. Merci de corriger.`,
    incorrectRestOrMealTimeRange: `Le temps saisi dans un No break/No lunch est incorrect.`,
    minIsFifteenMinutes: `Les plages de temps doivent être de 15 minutes au minimum.`,
  },
  cancel: 'Annuler',
  split: 'Séparer',
};

const requiredValidation = {
  pensionRequired: 'Le type de pension est requis',
  rangeShouldBeBetween: 'La valeur doit être entre {{from}} et {{to}}',
  rangeMaxLTE: 'La valeur doit être inférieure ou égale à {{value}}',
  rangeMinGTE: 'La valeur doit être supérieure ou égale à {{value}}',
};

const messages = {
  concurrencyErrorMessage: "L'entité a été modifié par un autre utilisateur, veuillez rafraîchir pour accéder aux données mises à jour.",
  saveSuccessMessage: 'Les modifications ont été enregistrées avec succès',
  serviceCallModificationRequestSentSuccessfuly: "Demande de modification d'appel de service envoyée avec succès.",
};

const errorPage = {
  notFoundErrorTitle: '{{entityName}} non trouvé.',
  notFoundErrorMessage: 'Veuillez vérifier le numéro ou votre URL.',
  defaultErrorTitle: 'Une erreur est survenue.',
  defaultErrorMessage: 'Veuillez vérifier votre connexion Internet ou ',
  backToList: 'Retour à la liste',
  entityType: {
    [EntityTypeErrorEnum.Unknown]: 'Entité',
    [EntityTypeErrorEnum.OrdreDeTravail]: 'Ordre de travail',
    [EntityTypeErrorEnum.TimeSheet]: 'Carte de temps',
    [EntityTypeErrorEnum.Incompatibility]: 'Incompatibilité',
    [EntityTypeErrorEnum.ContractAgreement]: 'Contrat de service',
  },
};

const addTimeRangeModal = {
  title: 'Ajouter',
  addTimeRange: 'Plage horaire',
  addCompilablePremium: 'Indemnité compilable',
  indemnityType: 'Type d’indemnité',
  errors: {
    required: 'Requis',
    emptyFields: 'Vous avez des champs vides',
    toGreaterThanFrom: `L'heure de fin doit être plus grande que l'heure de début`,
    invalidPosteDeTravail: 'Poste de travail invalide',
    incorrectRestOrMealTimeRange: `Le temps saisi dans un No break/No lunch est incorrect`,
    invalidGtNumber: 'Le numéro de GT est invalide',
    overlapsExistingTimeRange: 'La nouvelle plage de temps chevauche une plage de temps existante',
  },
  tableHeader: {
    linkTo: 'Lier au CS',
    from: 'De',
    to: 'À',
    quantity: 'Quantité',
    code: 'Poste',
    single: 'Simple',
    singleAndHalf: 'Demi',
    double: 'Double',
    variablePrime: 'Pr. Var',
    automaticPrime: 'Pr. Auto',
    rate: 'Taux',
    gtNumber: '#GT',
    workOrderNumber: 'Équi./WO',
  },
};

const operatorKind = {
  [CraneOperatorKind.AdditionalMan]: 'Homme additionnel',
  [CraneOperatorKind.Electrician]: 'Électricien',
  [CraneOperatorKind.Engineer]: 'Ingénieur',
  [CraneOperatorKind.Helper]: 'Aide-Grutier',
  [CraneOperatorKind.IndustrialDesigner]: 'Designer industriel',
  [CraneOperatorKind.Operator]: 'Opérateur',
  [CraneOperatorKind.Signalman]: 'Signaleur',
  [CraneOperatorKind.SiteSupervisor]: 'Superviseur de site',
  [CraneOperatorKind.TechnicalSupervisor]: 'Superviseur technique',
  [CraneOperatorKind.Welder]: 'Soudeur',
  [CraneOperatorKind.Trucker]: 'Chauffeur',
  [CraneOperatorKind.Mechanic]: 'Mécanicien',
  [CraneOperatorKind.Rigger]: 'Rigger',
  [CraneOperatorKind.ReplacedOperator]: 'Opérateur remplacé',
};

const validationError = {
  title: 'La sauvegarde {{type}} a échoué suite aux erreurs suivantes:',
  seeMore: 'Voir plus',
  seeLess: 'Voir moins',
  contractAgreement: 'du contrat de service',
  timesheet: 'de la feuille de temps',
  gtMustExist: 'Le numéro de GT est inexistant.',
  gtMustBeActive: 'Le numéro de GT est inactif.',
  gtClientMustBeSameAsOtClient: 'Le client associé au # GT ne correspond pas à celui de l’appel de service.',
  gtBranchMustBeSameAsOtBranch: 'La succursale de vente associée au # GT ne correspond pas à celle de l’OT.',
  mustBeFourDigits: 'Le numéro de GT doit être composé de 4 chiffres.',
  workKindNumberInactive: 'Genre inactif',
};

const comments = {
  noData: 'Aucun commentaire',
};
const cancelled = {
  ordreDeTravail: 'L’ordre de travail associé a été annulé',
};

const validationTags = {
  waitingForInformation: 'En attente d’info',
  returnForCorrection: 'Retour pour correction',
  submittedWithErrors: 'Soumis avec erreur(s)',
};

const pageMaintenance = {
  title: 'Maintenance WeLift',
  message: "L'application est présentement en maintenance. S.V.P. réessayer plus tard.",
};

export const common = {
  pageForbidden: "Vous n'êtes pas autorisé à voir cette page.",
  pageNotFound: 'La page est introuvable.',
  pageMaintenance,
  timeSheetEntryStatus,
  autocomplete,
  button,
  gridFooter,
  insufficientPermissions,
  navigation,
  serverConnectionErrorPanel,
  commonTableToolbar,
  savingErrorModal,
  cancelConfirmModal,
  somethingWentWrong,
  timeRangePicker,
  weekPicker,
  cancelEditWarningModal,
  workTypeWithCode,
  workType,
  workKind,
  serviceCallKind,
  ordreDeTravailStatus,
  timeSheetStatus,
  contractAgreementStatus,
  jobCode,
  travellingExpensesButtonGroup,
  timeRangeTable,
  splitTimeRangeOrPremiumModal,
  requiredValidation,
  contractAgreementSubmitted,
  replacedOperator,
  pendingNumber: 'En attente',
  ordreDeTravailValidationsStatuses,
  messages,
  addTimeRangeModal,
  errorPage,
  operatorKind,
  saveResult,
  validationError,
  at: 'à',
  attachmentResult,
  comments,
  cancelled,
  continuityFlags: validationTags,
};
