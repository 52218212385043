const workKindRuleForm = {
  informations: 'Informations',
  serviceCallKinds: 'Types d’appel de service',
  workTypes: 'Nature du travail',
  workTypeCategories: 'Catégories de nature du travail',
  craneOperatorKinds: 'Rôles',
  save: 'Enregistrer',
  saveSuccessMessage: 'Enregistré avec succès.',
  descriptionRequired: 'La description est requise',
  activateRule: 'Activer le genre',
  conditions: 'Conditions',
};

const automationRuleForm = {
  newAutomation: 'Nouvelle règle d’automatisation',
  editAutomation: 'Modifier la règle d’automatisation',
  contractAgreement: 'Contrat de service',
  contractAgreementItems: 'Item de contrat de service',
  workKind: 'Genre de travail',
  informations: 'Informations',
  serviceCallKinds: 'Types d’appel de service',
  workTypes: 'Nature du travail',
  workTypeCategories: 'Catégories de nature du travail',
  craneOperatorKinds: 'Rôles',
  save: 'Enregistrer',
  saveSuccessMessage: 'Enregistré avec succès.',
  descriptionRequired: 'La description est requise',
  contractAgreementSectionRequired: 'Le contrat de service est requis',
  workKindIdRequired: 'Le genre de travail est requis',
  conditions: 'Conditions',
  deleteSuccess: 'La règle d’automatisation a été supprimée avec succès.',
  deleteError: 'Une erreur s’est produite lors de la suppression de la règle.',
  confirmDeleteTitle: 'Supprimer la règle d’automatisation',
  confirmDeleteMessage: 'Êtes-vous sûr de vouloir supprimer cette règle d’automatisation?',
};

export const workKinds = {
  workKindRuleForm,
  automationRuleForm,
};
