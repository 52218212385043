import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { EmployeeAccessRecord } from '../models/models';
import { Path } from '../../paths';
import axios, { AxiosError } from 'axios';
import { useAccount, useMsal } from '@azure/msal-react';

export const useGetEmployeeClaimsAndRoles = (): UseQueryResult<EmployeeAccessRecord, AxiosError> => {
  const { instance } = useMsal();
  const account = useAccount();

  return useQuery({
    queryKey: [Path.EmployeeClaims],
    queryFn: () =>
      axios
        .get<EmployeeAccessRecord>('/employee/access')
        .then((response) => response.data)
        .catch((error) => {
          if (error instanceof AxiosError && [401, 403].includes(error.response?.status ?? 500)) {
            instance.logoutRedirect({
              account,
            });
          }
          return Promise.reject(error);
        }),
    gcTime: 1000 * 60 * 10,
  });
};
