import { useCallback, useEffect } from 'react';
import { GridSortModel } from '@mui/x-data-grid-premium';

import { ListContainer } from '../../../common/components/container/ListContainer';
import { gridSortToString, useSavedGridSort } from '../../../common/helpers/DatagridHelpers';
import { useTablePagination } from '../../../common/hooks/useTablePagination';
import { AutomationRulesTable, gridName, rowsPerPageOptions } from './AutomationRulesTable';
import { useFetchAutomationRules } from './hooks/useFetchAutomationRules';
import { useTableRowCount } from '../../../common/hooks/useTableRowCount';

export const AutomationRulesPage = (): JSX.Element | null => {
  const [sort, setSort] = useSavedGridSort(gridName);
  const { paginationModel, handlePaginationModelChange } = useTablePagination('pageSizeAutomationRules', rowsPerPageOptions[0]);
  const formattedOrderBy = gridSortToString(sort);

  const {
    isLoading,
    refetch,
    dataUpdatedAt: lastUpdatedAt,
    isRefetching,
    data = { count: 0, items: [] },
  } = useFetchAutomationRules({
    take: paginationModel.pageSize,
    skip: paginationModel.page * paginationModel.pageSize,
    orderBy: formattedOrderBy,
  });

  useEffect(() => {
    refetch();
  }, [refetch, paginationModel.page, formattedOrderBy]);

  const rowCount = useTableRowCount(data.count);

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      setSort(sortModel);
      handlePaginationModelChange((prev) => ({
        page: 0,
        pageSize: prev.pageSize,
      }));
    },
    [handlePaginationModelChange, setSort],
  );

  return (
    <ListContainer>
      <AutomationRulesTable
        isLoading={isLoading}
        sort={sort}
        count={rowCount}
        paginationModel={paginationModel}
        handlePaginationModelChange={handlePaginationModelChange}
        onSortModelChange={handleSortModelChange}
        automationRules={data.items}
        isRefetching={isRefetching}
        lastUpdatedAt={new Date(lastUpdatedAt)}
      />
    </ListContainer>
  );
};
