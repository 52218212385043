import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { acknowledgement as acknowledgementsEnTranslation } from './acknowledgements/__i18n__/en';
import { acknowledgement as acknowledgementsFrTranslation } from './acknowledgements/__i18n__/fr';
import { common as commonEnTranslation } from './common/__i18n__/en';
import { common as commonFrTranslation } from './common/__i18n__/fr';
import { configuration as configurationEnTranslation } from './configuration/__i18n__/en';
import { configuration as configurationFrTranslation } from './configuration/__i18n__/fr';
import { contractAgreement as contractAgreementEnTranslation } from './contractAgreement/__i18n__/en';
import { contractAgreement as contractAgreementFrTranslation } from './contractAgreement/__i18n__/fr';
import { dailyTimeSheet as dailyTimeSheetEnTranslation } from './dailyTimeSheet/__i18n__/en';
import { dailyTimeSheet as dailyTimeSheetFrTranslation } from './dailyTimeSheet/__i18n__/fr';
import { dispatch as dispatchEnTranslation } from './dispatch/__i18n__/en';
import { dispatch as dispatchFrTranslation } from './dispatch/__i18n__/fr';
import { layout as layoutEnTranslation } from './layout/__i18n__/en';
import { layout as layoutFrTranslation } from './layout/__i18n__/fr';
import { ordreDeTravail as ordreDeTravailEnTranslation } from './ordreDeTravail/__i18n__/en';
import { ordreDeTravail as ordreDeTravailFrTranslation } from './ordreDeTravail/__i18n__/fr';
import { privacyPolicy as privacyPolicyEnTranslation } from './privacyPolicy/__i18n__/en';
import { privacyPolicy as privacyPolicyFrTranslation } from './privacyPolicy/__i18n__/fr';
import { schemas as schemasEnTranslation } from './schemas/__i18n__/en';
import { schemas as schemasFrTranslation } from './schemas/__i18n__/fr';
import { timesheet as timesheetEnTranslation } from './timesheet/__i18n__/en';
import { timesheet as timesheetFrTranslation } from './timesheet/__i18n__/fr';
import { userSettings as settingsEnTranslation } from './userSettings/__i18n__/en';
import { userSettings as settingsFrTranslation } from './userSettings/__i18n__/fr';
import { validations as validationsEnTranslation } from './validations/__i18n__/en';
import { validations as validationsFrTranslation } from './validations/__i18n__/fr';
import { workKinds as workKindsEnTranslation } from './workKinds/__i18n__/en';
import { workKinds as workKindsFrTranslation } from './workKinds/__i18n__/fr';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    detection: {
      // cache user language on
      caches: ['localStorage'],

      excludeCacheFor: ['cimode'],

      // keys or params to lookup language from
      lookupLocalStorage: 'i18nextLng',
      // order and from where user language should be detected
      order: ['localStorage', 'navigator'], // languages to not persist (cookie, localStorage)
    },
    ns: [
      'common',
      'acknowledgement',
      'contractAgreement',
      'dispatch',
      'layout',
      'settings',
      'privacyPolicy',
      'timesheet',
      'validations',
      'ordreDeTravail',
      'dailyTimeSheet',
      'schemas',
    ],
    debug: false,
    fallbackLng: 'fr',
    returnNull: false,
    supportedLngs: ['en', 'fr'],
    // lng: 'fr', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    // debug: true,
    resources: {
      en: {
        acknowledgement: acknowledgementsEnTranslation,
        common: commonEnTranslation,
        contractAgreement: contractAgreementEnTranslation,
        dispatch: dispatchEnTranslation,
        layout: layoutEnTranslation,
        userSettings: settingsEnTranslation,
        privacyPolicy: privacyPolicyEnTranslation,
        timesheet: timesheetEnTranslation,
        validations: validationsEnTranslation,
        ordreDeTravail: ordreDeTravailEnTranslation,
        configuration: configurationEnTranslation,
        workKinds: workKindsEnTranslation,
        dailyTimeSheet: dailyTimeSheetEnTranslation,
        schemas: schemasEnTranslation,
      },
      fr: {
        acknowledgement: acknowledgementsFrTranslation,
        common: commonFrTranslation,
        contractAgreement: contractAgreementFrTranslation,
        dispatch: dispatchFrTranslation,
        layout: layoutFrTranslation,
        userSettings: settingsFrTranslation,
        privacyPolicy: privacyPolicyFrTranslation,
        timesheet: timesheetFrTranslation,
        validations: validationsFrTranslation,
        ordreDeTravail: ordreDeTravailFrTranslation,
        configuration: configurationFrTranslation,
        workKinds: workKindsFrTranslation,
        dailyTimeSheet: dailyTimeSheetFrTranslation,
        schemas: schemasFrTranslation,
      },
    },
  });

export default i18n;
