export enum WorkKindNumberValue {
  craneOperator = 1,
  craneAssistant = 2,
  trucker = 3,
  maintenance = 5,
  craneYardPreparation = 6,
  craneTransport = 7,
  nonBillableManTransport = 8,
  waiting = 9,
  breakageWaiting = 10,
  assemblyAndDisassembly = 11,
  breakageRepair = 12,
  billableBreakageRepair = 13,
  mechanic = 14,
  paidHoursNotOperated = 15,
  workPreparation = 16,
  twoManTransportDriver = 17,
  billableManTransport = 18,
  training = 20,
  cNESST = 21,
  specialLeaves = 22,
  representation = 24,
  craneOperatorsNonOperatedRental = 25,
  craneAssistantNotOperatedRental = 26,
  siteManTransfer = 28,
  restOrMealPeriod = 35,
  craneTransportAssistant = 37,
  nonBillableAssemblyAndDisassembly = 31,
  nonBillableTwoManTransportDriver = 47,
  roomAndBoardPipeline = 50,
  meal = 51,
  roomAndBoardDay = 52,
  roomAndBoardTwoPlusWeeks = 53,
  carKmAllowance = 54,
  travelExpenses49To72Km = 55,
  travelExpenses73To88Km = 56,
  travelExpenses89To119Km = 57,
  travelExpenses120AndMoreKm = 58,
  travelExpensesSiteTransfer = 69,
  parking = 59,
  taxiBusOrTolls = 62,
  administration = 0,
  escort = 19,
  transferManToLoad = 38,
  vacation = 40,
  holiday = 42,
  severancePay = 43,
  travelExpenses61To90Km = 60,
  travelExpenses91To119Km = 61,
  towerCrane = 1004,
  menTransport = 1008,
  roadBreakageWaiting = 1010,
  repair = 1012,
  presentationAtWork = 1016,
  craneOperatorMenToLoad = 1018,
  craneOperatorTransportToLoad = 1017,
  csst = 1021,
  sickness = 1041,
  roomAndBoardWeek = 1053,
  autoKmAllowance = 1054,
  taxiBusParking = 1059,
}
