import { RestartAlt as RestartAltIcon } from '@mui/icons-material';
import { Button } from '@mui/material';
import { GridColumnVisibilityModel, GridValidRowModel } from '@mui/x-data-grid-premium';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getDefaultColumnVisibilityModel } from '../../helpers/DatagridHelpers';
import { HeaderInfo } from '../../types/table/HeaderInfo';

type Props<RowModel extends GridValidRowModel> = {
  headersInformation: Pick<HeaderInfo<RowModel>, 'field' | 'hide'>[];
  resetColumns: (defaultVisibilityModel: GridColumnVisibilityModel) => void;
};

export const ResetColumnsButton = <RowModel extends GridValidRowModel>({ headersInformation, resetColumns }: Props<RowModel>) => {
  const { t } = useTranslation('common');

  const defaultColumnVisibilityModel: GridColumnVisibilityModel = useMemo<GridColumnVisibilityModel>(
    () => getDefaultColumnVisibilityModel<RowModel>(headersInformation),
    [headersInformation],
  );

  return (
    <Button
      color='inherit'
      startIcon={<RestartAltIcon />}
      sx={{ textTransform: 'uppercase', ml: 'auto' }}
      onClick={() => resetColumns(defaultColumnVisibilityModel)}>
      {t('commonTableToolbar.resetColumnsButton')}
    </Button>
  );
};
