import { diff } from 'deep-object-diff';
import equal from 'fast-deep-equal';
import { useRef } from 'react';
import { ErrorOption, FieldErrors, FieldValues, Path, ResolverOptions, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

type Props<T extends FieldValues> = Partial<UseFormProps<T>> & { disableHack?: boolean };
const defaultResolverResult = { values: {}, errors: {} };

/**
 * Hook to replace useForm from react-hook-form
 * The resolver is not able to set custom errors on field change
 * the resolver in the field context should set all errors in the form state and not its own state (see the link below to understand the issue)
 * the lib set all resolver errors only when triggering the onSubmit event
 * this is a workaround to set the errors in the form state when the resolver is a function
 * it will create a second rerender to be able to reset errors with the 'errors' prop
 * but it will prevent triggering the schema validator twice.
 * @param props - useForm props
 * @returns useForm return
 * @link https://github.com/react-hook-form/react-hook-form/blob/b6bbd8a178f355632c05f7094c586773a262f78b/src/logic/createFormControl.ts#L736
 * @link https://github.com/react-hook-form/react-hook-form/issues/11526
 */
function useGuayForm<T extends FieldValues>(props: Props<T>): UseFormReturn<T> {
  const prevErrors = useRef<FieldErrors<T>>({});
  const result = useForm<T>({
    ...props,
    resolver:
      typeof props.resolver === 'function'
        ? async (_values: T, _context: object, _options: ResolverOptions<T>) => {
            const { values, errors } = (await props.resolver?.(_values, _context, _options)) ?? defaultResolverResult;
            if (!props.disableHack) {
              if (!equal(prevErrors.current, errors)) {
                const _diff = diff(prevErrors.current, errors);
                _errorsToPathStringAndError(_diff).forEach(([path]) => {
                  result.clearErrors(path as Path<T>);
                });
                _errorsToPathStringAndError(errors).forEach(([path, error]) => {
                  result.setError(path as Path<T>, error as ErrorOption);
                });
              }
              prevErrors.current = errors;
            }
            return { values, errors };
          }
        : undefined,
  });
  return result;
}

/**
 * Convert the errors object to a string path
 * @param errors - errors object
 * @returns path string and error
 *  */
function _errorsToPathStringAndError<T extends FieldValues>(errors: FieldErrors<T>): [Path<T>, FieldErrors<T>][] {
  const result: Map<Path<T>, FieldErrors<T>> = new Map();
  for (const key in errors) {
    const error = errors[key];
    if (Array.isArray(error)) {
      for (let i = 0; i < error.length; i++) {
        const subError = error[i];
        for (const subKey in subError) {
          const subSubError = subError[subKey];
          result.set(`${key}[${i}].${subKey}` as Path<T>, subSubError);
        }
      }
    } else {
      result.set(key as Path<T>, error as FieldErrors<T>);
    }
  }
  return [...result];
}

export default useGuayForm;
