import { GetContractAgreementRecord } from '../../models/shared/models';
import { DefinedInitialDataOptions, QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import { Path } from '../../../paths';
import axios from 'axios';
import { useLoaderData } from 'react-router-dom';
import { ContractAgreementLoaderData } from '../../../validationNext/loaders/contractAgreementLoader';

export const getContractAgreementQueryKey = (id: string | undefined): QueryKey => [Path.ValidationsPage, Path.ContractAgreementsPage, id];
export const contractAgreementQueryOptions = (
  id: string | undefined,
): Pick<DefinedInitialDataOptions<GetContractAgreementRecord>, 'queryKey' | 'queryFn' | 'staleTime'> => ({
  queryKey: getContractAgreementQueryKey(id),
  queryFn: async () => axios.get<GetContractAgreementRecord>(`/contract-agreements/${id}`).then((response) => response.data),
  staleTime: 2 * 60 * 1000,
});

export const useGetContractAgreement = (contractAgreementId: string | undefined): UseQueryResult<GetContractAgreementRecord, unknown> => {
  const { contractAgreement } = useLoaderData() as ContractAgreementLoaderData;
  return useQuery({
    ...contractAgreementQueryOptions(contractAgreementId),
    initialData: contractAgreement?.data ?? undefined,
    enabled: !!contractAgreementId,
  });
};

// export const useGetContractAgreement = (contractAgreementId: string | undefined): UseFetchResult<GetContractAgreementRecord> => {
//   const queryFn = useMemo(() => {
//     if (!contractAgreementId) {
//       return () => Promise.reject(new Error('Contract Agreement ID is required'));
//     }

//     return createUrlFetcher<GetContractAgreementRecord>(`/contract-agreements/${contractAgreementId}`);
//   }, [contractAgreementId]);

//   const isEnabled = Boolean(contractAgreementId);

//   return useFetch({
//     queryFn,
//     enabled: isEnabled,
//   });
// };
