import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { defaultTextColor } from '../constants/colors';
import { ActionButton } from './button/ActionButton';

type Props = {
  title: string;
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  confirmText?: string;
  confirmStyle?: SxProps;
};

export const ConfirmationModal = ({
  title,
  message,
  onConfirm,
  onCancel,
  isOpen,
  confirmText,
  confirmStyle,
  maxWidth = 'xs',
}: Props): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <Dialog open={isOpen} maxWidth={maxWidth} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          fontWeight={400}
          fontFamily='Inter'
          fontSize={16}
          fontStyle='normal'
          sx={{ paddingTop: 2, justifyContent: 'center' }}
          variant='subtitle1'
          color='black'>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ActionButton label={t('button.cancel')} sx={{ ml: 2, color: defaultTextColor, fontWeight: 500 }} onClick={onCancel} />
        <ActionButton
          sx={confirmStyle}
          label={confirmText ? confirmText : t('button.continue')}
          color='secondary'
          onClick={onConfirm}
          variant='contained'
        />
      </DialogActions>
    </Dialog>
  );
};
