import axios, { AxiosResponse } from 'axios';
import { useState } from 'react';
import { AutomationRuleRecord } from '../../models/automatisationModel';

type UseCreateAutomationRuleResult = {
  deleteAutomationRule: (id: string) => Promise<AxiosResponse>;
  isLoading: boolean;
};

export const useDeleteAutomationRule = (): UseCreateAutomationRuleResult => {
  const [isLoading, setIsLoading] = useState(false);

  const deleteAutomationRule = async (id: string): Promise<AxiosResponse<AutomationRuleRecord>> => {
    setIsLoading(true);
    const response = await axios.delete(`/automation-rules/${id}`);
    setIsLoading(false);
    return response;
  };

  return { deleteAutomationRule, isLoading };
};
