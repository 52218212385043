import { Box, Button, Link, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { black, defaultDarkColor, white } from '../../constants/colors';

type Props = {
  title: string;
  content: string;
  contactAdmin?: boolean;
  buttonLabel?: string;
  buttonCallback?: () => void;
};

export const ErrorCard = ({
  title,
  content,
  contactAdmin = false,
  buttonLabel,
  buttonCallback = (): void => window.location.reload(),
}: Props): JSX.Element => {
  const { t } = useTranslation('common');
  return (
    <Box
      bgcolor='white'
      my={2}
      flexGrow='1'
      width='100%'
      minWidth='fit-content'
      role='status'
      className='error-card'
      p={5}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'>
      <Typography fontSize='1.25rem' fontWeight='500' lineHeight='32px' textAlign='center'>
        {title}
      </Typography>
      <Box maxWidth='300px' textAlign='center' mb={2} mt={1}>
        <Typography display='inline' fontSize='0.875rem'>
          {content}
        </Typography>
        {contactAdmin && (
          <Link
            color='primary'
            sx={{ textDecoration: 'underline' }}
            textTransform='lowercase'
            display='inline'
            fontWeight='bold'
            fontSize='0.875rem'>
            {t('serverConnectionErrorPanel.contactAdmin')}
          </Link>
        )}
      </Box>
      <Button color='info' variant='contained' sx={styles.button} onClick={buttonCallback}>
        <Typography textTransform='uppercase' ml={1}>
          {buttonLabel ?? t('serverConnectionErrorPanel.refreshButtonLabel')}
        </Typography>
      </Button>
    </Box>
  );
};

const styles: { [key: string]: SxProps } = {
  button: {
    backgroundColor: defaultDarkColor,
    textTransform: 'uppercase',
    borderRadius: '4px',
    color: white,
    '&:hover': {
      backgroundColor: black,
    },
  },
};
