import { Box, Theme } from '@mui/material';

type Props = { children: React.ReactNode };

export function ListContainer({ children }: Props): JSX.Element {
  return (
    <Box flexGrow={1} sx={{ borderRadius: 1, overflow: 'auto' }} bgcolor={(theme: Theme) => theme.palette.secondary.main}>
      {children}
    </Box>
  );
}
