import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { ErrorPage } from './ErrorPage';
import { EntityTypeErrorEnum, getErrorMessage } from './ErrorMessageHelpers';
import { useTranslation } from 'react-i18next';

type Props = {
  entityType?: EntityTypeErrorEnum;
  header?: string;
  backButtonUrl?: string;
  backButtonLabel?: string;
  error: AxiosError;
};

export const AxiosErrorPage = ({ entityType, header, backButtonUrl, backButtonLabel, error }: Props): JSX.Element => {
  const { t } = useTranslation('common', { keyPrefix: 'errorPage' });
  const { title, message, contactAdmin } = useMemo(() => getErrorMessage(t, entityType, error.response?.status), [error, t, entityType]);
  return (
    <ErrorPage
      headerText={header}
      backButtonUrl={backButtonUrl}
      backButtonLabel={backButtonLabel}
      title={title}
      message={message}
      contactAdmin={contactAdmin}
    />
  );
};
