const workKindRuleForm = {
  informations: 'Information',
  serviceCallKinds: 'Service Call Type',
  workTypes: 'Work Type',
  workTypeCategories: 'Work Type Categories',
  craneOperatorKinds: 'Roles',
  save: 'Save',
  saveSuccessMessage: 'Save successful',
  descriptionRequired: 'Description is required',
  activateRule: 'Activate work kind',
  conditions: 'Conditions',
};
const automationRuleForm = {
  newAutomation: 'New Automation rule',
  editAutomation: 'Edit Automation rule',
  contractAgreement: 'Contract Agreement',
  workKind: 'Work Kind',
  contractAgreementItems: 'Contract Agreement Items',
  informations: 'Information',
  serviceCallKinds: 'Service Call Type',
  workTypes: 'Work Type',
  workTypeCategories: 'Work Type Categories',
  craneOperatorKinds: 'Roles',
  jobCodes: 'Roles',
  save: 'Save',
  saveSuccessMessage: 'Save successful',
  descriptionRequired: 'Description is required',
  contractAgreementSectionRequired: 'Contract Agreement is required',
  workKindIdRequired: 'Work Kind is required',
  conditions: 'Conditions',
  deleteSuccess: 'Automation rule has been successfully deleted.',
  deleteError: 'An error occurred while deleting the rule.',
  confirmDeleteTitle: 'Delete Automation Rule',
  confirmDeleteMessage: 'Are you sure you want to delete this automation rule?',
};

export const workKinds = {
  workKindRuleForm,
  automationRuleForm,
};
