import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, FormHelperText, MenuItem, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { isEmpty, toNumber } from 'lodash';
import { RefObject, useCallback, useEffect, useMemo } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { array, mixed, object, ObjectSchema, string } from 'yup';
import { MainCard } from '../../../common/components/container/MainCard';
import { MultiSelectChipInput } from '../../../common/components/inputs/MultiSelectChipInput';
import { FormBinderRef } from '../../../common/hooks/useFormBinderRef';
import useGuayForm from '../../../common/hooks/useGuayForm';
import { useBlockerContext } from '../../../common/provider/BlockerProvider';
import { CraneOperatorKind, ServiceCallKind, WorkType, WorkTypeCategory } from '../../../generated/schemas';

import { buildEnumItems } from '../helpers';

import { TFunction } from 'i18next';
import { ContractAgreementSections } from '../../../validationNext/models/models';
import { AutomationRuleRecord, UpdateAutomationRuleRequest } from '../models/automatisationModel';
import { WorkKindRuleListItemRecord } from '../models/workKindsModel';
import { useCreateAutomationRule } from './hooks/useCreateAutomationRule';
import { useUpdateAutomationRule } from './hooks/useUpdateAutomationRule';
import { mapRecordToForm } from './mapper';
import { useNavigate } from 'react-router-dom';
import { Path } from '../../../paths';

export type AutomationRuleFormDataDto = Omit<UpdateAutomationRuleRequest, 'contractAgreementSection'> & {
  contractAgreementSection: UpdateAutomationRuleRequest['contractAgreementSection'] | null;
};

export const getAutomationRuleYupSchema = (t: TFunction<'translation', undefined>): ObjectSchema<AutomationRuleFormDataDto> => {
  return object().shape({
    description: string().required(t('automationRuleForm.descriptionRequired', { ns: 'workKinds' })),
    serviceCallKinds: array().defined(),
    workTypes: array().defined(),
    workTypeCategories: array().defined(),
    operatorKinds: array().defined(),
    contractAgreementSection: mixed<ContractAgreementSections>()
      .nullable()
      .required(t('automationRuleForm.contractAgreementSectionRequired', { ns: 'workKinds' })),
    workKindId: string().required(t('automationRuleForm.workKindIdRequired', { ns: 'workKinds' })),
  });
};

export type Props = {
  mode: 'create' | 'edit';
  automationRule: AutomationRuleRecord | null;
  actionsRef: RefObject<FormBinderRef>;
  workKinds: WorkKindRuleListItemRecord[];
  isLoading: boolean;
};

const automationRuleChannel = 'AutomationRule';
const MENU_MAX_HEIGHT = 200;
export const AutomationRuleForm = (WorkKindRuleFormProps: Props): JSX.Element => {
  const { automationRule, actionsRef, isLoading, workKinds, mode } = WorkKindRuleFormProps;

  const { t, i18n } = useTranslation();
  const workTypeItems = useMemo(() => buildEnumItems(t, WorkType, 'common:workTypeWithCode'), [t]);
  const workTypeCatgeoriesItems = useMemo(() => buildEnumItems(t, WorkTypeCategory, 'ordreDeTravail:workTypeCategory'), [t]);
  const serviceCallKindsItems = useMemo(() => buildEnumItems(t, ServiceCallKind, 'common:serviceCallKind'), [t]);
  const craneOperatorKind = useMemo(() => buildEnumItems(t, CraneOperatorKind, 'common:operatorKind'), [t]);
  const contractAgreementSections = useMemo(() => buildEnumItems(t, ContractAgreementSections, 'schemas:contractAgreementSections'), [t]);

  const blocker = useBlockerContext();
  const navigate = useNavigate();
  const defaultValues = useMemo<AutomationRuleFormDataDto>(() => {
    return mapRecordToForm(automationRule);
  }, [automationRule]);

  const methods = useGuayForm<AutomationRuleFormDataDto>({
    defaultValues,
    resolver: yupResolver(getAutomationRuleYupSchema(t)),
    mode: 'all',
  });
  const { control, formState, handleSubmit, reset } = methods;
  const { updateAutomationRule } = useUpdateAutomationRule();
  const { createAutomationRule } = useCreateAutomationRule();

  const getIsDirty = useCallback(() => {
    return formState.isDirty;
  }, [formState.isDirty]);

  const onValid = useCallback(
    async (data: AutomationRuleFormDataDto) => {
      if (mode === 'create') {
        blocker.unsubscribe(automationRuleChannel);
        const { data: record } = await createAutomationRule({ input: data });
        navigate(`/${Path.WorkKindsPage}/${Path.AutomationRulesPage}/${record.id}`, { replace: true });
        reset(mapRecordToForm(record));
        blocker.subscribe(automationRuleChannel, getIsDirty, {
          onBlock: () => blocker.subscribe(automationRuleChannel, getIsDirty),
          onStay: () => blocker.subscribe(automationRuleChannel, getIsDirty),
        });
        return;
      }
      if (!automationRule) return;
      const { data: record } = await updateAutomationRule({ automationId: automationRule.id, input: data });
      reset(mapRecordToForm(record));
    },
    [mode, automationRule, updateAutomationRule, reset, blocker, createAutomationRule, navigate, getIsDirty],
  );

  const currentDefaultValues = formState.defaultValues;

  const sortedWorkKinds = useMemo(() => {
    return workKinds.toSorted((a, b) => {
      const codeA = toNumber(a.code);
      const codeB = toNumber(b.code);

      return codeA - codeB;
    });
  }, [workKinds]);

  useEffect(() => {
    if (isEmpty(currentDefaultValues)) return;
    blocker.subscribe(automationRuleChannel, getIsDirty, {
      onBlock: () => blocker.subscribe(automationRuleChannel, getIsDirty),
      onStay: () => blocker.subscribe(automationRuleChannel, getIsDirty),
    });

    return () => blocker.unsubscribe(automationRuleChannel);
  }, [blocker, getIsDirty, currentDefaultValues]);

  useEffect(() => {
    const currentActionsRef = actionsRef.current;
    currentActionsRef?.bindFormSubmit<AutomationRuleFormDataDto>(
      'automationRule',
      handleSubmit,
      onValid,
      currentDefaultValues as AutomationRuleFormDataDto,
    );
    return () => {
      currentActionsRef?.unbindFormSubmit('automationRule');
    };
  }, [actionsRef, handleSubmit, onValid, currentDefaultValues]);

  useEffect(() => {
    actionsRef.current?.bindFormStateDisabled('automationRule', !formState.isValid);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      actionsRef.current?.unbindFormStateDisabled('automationRule');
    };
  }, [actionsRef, formState.isValid]);

  useEffect(() => {
    actionsRef.current?.bindReset('automationRule', reset);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      actionsRef.current?.unbindReset('automationRule');
    };
  }, [actionsRef, reset]);

  useEffect(() => {
    if (!automationRule) return;
    reset(mapRecordToForm(automationRule));
  }, [isLoading, reset, automationRule]);

  const showError = mode === 'create' ? formState.isSubmitted : formState.isDirty;
  return (
    <FormProvider {...methods}>
      <Stack gap={4} mt={2} flex={1} direction='column'>
        <Stack>
          <Typography variant='h2'>{t('automationRuleForm.informations', { ns: 'workKinds' })}</Typography>
          <MainCard>
            <Stack direction='column' gap={2} width='100%'>
              <Controller
                name='description'
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={!!fieldState.error && formState.isDirty}>
                    <TextField size='small' sx={{ width: '100%' }} {...field} label='Description' error={!!fieldState.error && showError} />
                    {!!fieldState.error && showError && (
                      <FormHelperText error={!!fieldState.error} sx={{ marginTop: '3px !important' }}>
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Stack>
          </MainCard>
        </Stack>
        <Stack>
          <Typography variant='h2'>{t('automationRuleForm.contractAgreement', { ns: 'workKinds' })}</Typography>
          <MainCard>
            <Controller
              name='contractAgreementSection'
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth error={!!fieldState.error && formState.isDirty}>
                  <TextField
                    sx={{
                      width: '100%',
                    }}
                    {...field}
                    size='small'
                    select
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            maxHeight: MENU_MAX_HEIGHT,
                            overflowY: 'auto',
                          },
                        },
                      },
                    }}
                    error={!!fieldState.error && showError}
                    label={t('automationRuleForm.contractAgreementItems', { ns: 'workKinds' })}>
                    {contractAgreementSections.map((item) => (
                      <MenuItem key={`contractAgreement-${item.value}`} value={item.value} selected={item.value === field.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {!!fieldState.error && showError && (
                    <FormHelperText error={!!fieldState.error} sx={{ marginTop: '3px !important' }}>
                      {fieldState.error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </MainCard>
        </Stack>
        <Stack>
          <Typography variant='h6'>{t('automationRuleForm.conditions', { ns: 'workKinds' })}</Typography>
          <MainCard>
            <Stack direction='column' gap={2} flex={1}>
              <Controller
                name='serviceCallKinds'
                control={control}
                render={({ field, fieldState }) => (
                  <MultiSelectChipInput
                    {...field}
                    sx={{ width: '100%', m: 0 }}
                    items={serviceCallKindsItems}
                    label={t('automationRuleForm.serviceCallKinds', { ns: 'workKinds' })}
                    value={field.value}
                    error={!!fieldState.error}
                    onChange={(val) => field.onChange(val)}
                  />
                )}
              />
              <Controller
                name='workTypes'
                control={control}
                render={({ field, fieldState }) => (
                  <MultiSelectChipInput
                    {...field}
                    sx={{ width: '100%', m: 0 }}
                    items={workTypeItems}
                    label={t('automationRuleForm.workTypes', { ns: 'workKinds' })}
                    value={field.value}
                    error={!!fieldState.error && showError}
                    onChange={(val) => field.onChange(val)}
                  />
                )}
              />
              <Controller
                name='workTypeCategories'
                control={control}
                render={({ field, fieldState }) => (
                  <MultiSelectChipInput
                    {...field}
                    sx={{ width: '100%', m: 0 }}
                    items={workTypeCatgeoriesItems}
                    label={t('automationRuleForm.workTypeCategories', { ns: 'workKinds' })}
                    value={field.value}
                    error={!!fieldState.error && showError}
                    onChange={(val) => field.onChange(val)}
                  />
                )}
              />
              <Controller
                name='operatorKinds'
                control={control}
                render={({ field, fieldState }) => (
                  <MultiSelectChipInput
                    {...field}
                    sx={{ width: '100%', m: 0 }}
                    items={craneOperatorKind}
                    label={t('automationRuleForm.craneOperatorKinds', { ns: 'workKinds' })}
                    value={field.value}
                    error={!!fieldState.error && showError}
                    onChange={(val) => field.onChange(val)}
                  />
                )}
              />
            </Stack>
          </MainCard>
        </Stack>
        <Stack mb={30}>
          <Typography variant='h6'>{t('automationRuleForm.workKind', { ns: 'workKinds' })}</Typography>
          <MainCard>
            <Controller
              name='workKindId'
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth error={!!fieldState.error && formState.isDirty}>
                  <TextField
                    label={t('automationRuleForm.workKind', { ns: 'workKinds' })}
                    sx={{ width: '100%' }}
                    {...field}
                    size='small'
                    select
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          sx: {
                            maxHeight: MENU_MAX_HEIGHT,
                            overflowY: 'auto',
                          },
                        },
                      },
                    }}
                    error={!!fieldState.error && showError}>
                    {sortedWorkKinds.map((item) => (
                      <MenuItem key={`workKind-${item.id}`} value={item.id}>
                        {i18n.languages[0] === 'fr' ? `${item.code} - ${item.nameFr}` : `${item.code} - ${item.nameEn}`}
                      </MenuItem>
                    ))}
                  </TextField>
                  {!!fieldState.error && showError && (
                    <FormHelperText error={!!fieldState.error} sx={{ marginTop: '3px !important' }}>
                      {fieldState.error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </MainCard>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
