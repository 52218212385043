import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Location } from 'react-router-dom';
import { defaultTextColor, sectionTitleColor } from '../../constants/colors';
import { ActionButton } from '../button/ActionButton';
import { LoadingButton } from '../button/LoadingButton';

export interface BlockedEvent {
  currentLocation: Location;
  nextLocation: Location;
  historyAction: string;
}

type Props = {
  open: boolean;
  disableSave?: boolean;
  onSave: () => Promise<void> | void;
  onClose: () => void;
  onQuit: () => void;
  isSaving?: boolean;
};

const prefix = 'cancelEditWarningModal.';

export const CancelEditWarningModal = ({ open, onClose, onQuit, disableSave, onSave, isSaving }: Props): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <Dialog open={open} aria-label='cancel-edit-warning-modal'>
      <DialogTitle variant='h6'>{t(`${prefix}title`)}</DialogTitle>
      <DialogContent>
        <Typography color={sectionTitleColor} fontSize={16} fontWeight={300} my={2}>
          {t(`${prefix}content`)}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <ActionButton label={t(`${prefix}quit`)} sx={{ color: defaultTextColor, fontWeight: 500 }} onClick={onQuit} />
        <Box>
          <ActionButton label={t(`${prefix}stayOnEdit`)} sx={{ mr: '.5rem', color: defaultTextColor, fontWeight: 500 }} onClick={onClose} />
          <LoadingButton
            color='secondary'
            variant='contained'
            disabled={disableSave || isSaving}
            onClick={onSave}
            isLoading={isSaving ?? false}
            sx={{ textTransform: 'uppercase' }}>
            {t(`${prefix}saveAndQuit`)}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
